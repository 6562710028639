<template>
  <div class="tile is-child notification">
    <div class="columns" v-for="(fee,fee_index) in fees" :key="fee_index">
      <template v-if="fee.status==1">
        <div class="column is-full-desktop" :key="fee_index">
          <b-icon icon="percent" size="is-small" v-if="fee.type=='percentage'"></b-icon>
          <b-icon icon="currency-usd" size="is-small" v-else></b-icon>
          <b-checkbox
            v-model="broker_fees"
            type="is-warning"
            @input="triggerUpdate"
            :native-value="fee_index"
          >
            <span class="is-size-7">{{fee.title}}</span>
          </b-checkbox>
        </div>
        <div class="column is-pulled-right">${{fee.amount}}</div>
      </template>
    </div>

    <div class="columns">
      <div class="column is-full">
        <b-icon icon="currency-usd" size="is-small"></b-icon>
        <b-checkbox
          v-model="manual_fee_toggle"
          @input="triggerUpdate"
          type="is-warning"
          native-value="manual_fee"
        >
          <span class="is-warning is-size-7">Manual Fee</span>
        </b-checkbox>
        <div class="field is-horizontal">
          <div class="field-body">
            <div class="field">
              <div class="select is-small">
                <select @change="triggerUpdate" v-model="manual_fee_type">
                  <option value="fixed">Fixed</option>
                  <option value="percentage">Percentage</option>
                </select>
              </div>
            </div>
            <div class="field">
              <currency-input-component
                v-if="manual_fee_type=='fixed'"
                @input="update_fee"
                onclick="this.select();"
                :answer="manual_fee"
              ></currency-input-component>

              <number-input-component
                v-else
                @input="update_fee"
                onclick="this.select();"
                :answer="manual_fee"
              ></number-input-component>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["fees", "selected_fees"],
  data() {
    return {
      manual_fee: 0,
      manual_fee_toggle: false,
      manual_fee_type: "fixed",
      broker_fees: []
    };
  },

  mounted() {
    if (this.selected_fees) {
      if (this.selected_fees.manual_fee) {
        this.manual_fee = this.selected_fees.manual_fee
          ? this.selected_fees.manual_fee
          : 0;
        this.manual_fee_toggle = true;
        this.manual_fee_type = this.selected_fees.manual_fee_type;
      }
      this.broker_fees = this.selected_fees.broker_fees;
    }
  },
  methods: {
    update_fee(val) {
      this.manual_fee = val;
      this.triggerUpdate();
    },
    triggerUpdate() {
      console.log(this.manual_fee_type);
      let broker_fee_container = {
        manual_fee: this.manual_fee,
        manual_fee_toggle: this.manual_fee_toggle,
        manual_fee_type: this.manual_fee_type,
        broker_fees: this.broker_fees
      };
      this.$emit("input", broker_fee_container);
    }
  }
};
</script>