<template >
  <ul class="menu-list">
    <!-- <p class="menu-label">
      <router-link
        :class="{'is-active is-success': ($route.name=='insurer') }"
        :to="{ name: 'insurer',params: { insurer_id : insurer.id ,insurer_name : slug(insurer.name)}}"
      >{{insurer.name}}</router-link>
    </p> -->
    <li>
      <router-link
        :class="{'is-active has-background-danger': ($route.name=='insurer.brokers') }"
        :to="{ name: 'insurer.brokers',params: { schemes:schemes,  insurer_id : insurer.id,insurer_name : slug(insurer.name), brokers: insurer.brokers}}"
      >Brokers</router-link>
    </li>
    <li>
      <router-link
        :class="{'is-active has-background-danger': $route.name=='insurer.policies' }"
        :to="{ name: 'insurer.policies' ,params: { insurer_id : insurer.id ,insurer_name : slug(insurer.name) }}"
      >Policies</router-link>
    </li>
    <li v-bind="schemes">
      <a  :class="{'is-active': $route.name=='insurer.scheme'  }" @click="showSchemes=!showSchemes">Schemes</a>
      <ul lass="menu-list">
        <li v-for="(scheme , scheme_index) in schemes" :key="scheme_index">
          <router-link 
          :class="{'is-active has-background-light': ($route.name=='insurer.scheme' && $route.params.id==scheme.id) }" 
          :to="{ name: 'insurer.scheme',params: {insurer_id : insurer.id, id : scheme.id ,insurer_name : slug(insurer.name)}}">

            <span
              class="tag"
              :style="{ background: scheme.settings.theme['background_color'] ,color: scheme.settings.theme['text_color'] }"
            >{{scheme.code}}</span>
          </router-link>
        </li>
      </ul>
    </li>


  </ul>
</template>

<script>
import mixin from "../../mixin";
import axios from "axios";

export default {
  props: ["insurer"],
  mixins:[mixin],
  data() {
    return {
      schemes: {},
      showSchemes: true
    };
  },
  watch:
  {

  },
  mounted() {
  this.GetSchems();
  },
  methods: {
    GetSchems() {
      let _self = this;
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/insurer/" +
            _self.insurer.id +
            "/schemes",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            }
          }
        )
        .then(response => {
          console.log(response.data);
          _self.schemes = response.data;
        })
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.removeItem("token");
            store.commit("logoutUser");
            _self.$router.push({ name: "login" });
          }
        });
    }
  }
};
</script>