<template>
  <div class="content">
    <b-table
      :data="referrals"
      :bordered="false"
      :striped="false"
      :narrowed="false"
      :hoverable="false"
      :loading="false"
      detailed
      detail-key="id"
      :focusable="false"
      :mobile-cards="true"
      :show-detail-icon="true"
    >
      <template slot-scope="props">
        <b-table-column field="referral_date" label="Referral Date">{{ props.row.referral_date }}</b-table-column>
        <b-table-column>
          <div
            class="buttons m-sm is-clearfix"
            v-if="access=='insurer' && props.row.acceptance_date==null && props.row.declined_date==null"
          >
            <b-button
              size="is-small"
              icon-left="thumb-up"
              @click="resolution(props.row.id,'accept')"
            ></b-button>
            <b-button
              size="is-small"
              icon-left="thumb-down"
              @click="resolution(props.row.id,'decline')"
            ></b-button>
          </div>
          <div v-else-if="props.row.acceptance_date!=null">
            <b-taglist attached>
              <b-tag type="has-background-success has-text-white">Accepted</b-tag>
              <b-tag type="is-light">{{ props.row.acceptance_date }}</b-tag>
            </b-taglist>
          </div>
          <div v-else-if="props.row.declined_date!=null ">
            <b-taglist attached>
              <b-tag type="has-background-danger has-text-white">Declined</b-tag>
              <b-tag type="is-light">{{ props.row.referral_date }}</b-tag>
            </b-taglist>
          </div>
          <div v-else class="p-sm">
            <span class="has-background-warning has-text-white tag">Pending</span>
          </div>
          <policy-referral-component
            @cancelled="cancel"
            @updated="update($event,props.row.id)"
            v-if="active_id==props.row.id"
            :mode="mode"
            :referral="props.row"
          ></policy-referral-component>
        </b-table-column>
      </template>

      <template slot="detail" slot-scope="props" v-if="props.row.description">
        <tr>
          <td>{{props.row.description}}</td>
        </tr>
      </template>

      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="emoticon-sad" size="is-large"></b-icon>
            </p>
            <p>Nothing here.</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>

<script>
import PolicyReferralComponent from "../../components/policy/Referral";
export default {
  props: ["referrals", "access"],
  components: {
    "policy-referral-component": PolicyReferralComponent
  },
  data() {
    return {
      active_id: null,
      mode: null,
      id:null,
    };
  },

  mounted() {
    // console.log(this.referrals);
  },
  methods: {
    update(data, id) {
      data.id=id;
      this.active_id = null;
      this.mode = null;
      this.$emit("updated", {'request_type':'referral', 'request_mode':'store', 'data': data });
    },
    resolution(id, mode) {
      this.active_id = id;
      this.mode = mode;
    },
    cancel() {
      this.active_id = null;
      this.mode = null;
    }
  }
};
</script>