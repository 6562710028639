<template>
  <div class="card is-fullheight">
    <div class="card-content">
      <b-table
        :data="brokers"
        :bordered="false"
        :striped="false"
        :narrowed="true"
        :hoverable="false"
        :focusable="false"
        :centered="false"
      >
        <template slot-scope="props">
          <b-table-column field="id"  label="id">{{ props.row.id }}</b-table-column>
          <b-table-column field="name"  label="name">{{ props.row.name }}</b-table-column>
           <b-table-column field="status" label="status">{{ props.row.status }}</b-table-column>

        </template>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>
                <b-icon icon="emoticon-sad" size="is-large"></b-icon>
              </p>
              <p>Nothing here.</p>
            </div>
          </section>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import mixin from "../../mixin";
export default {
  props: ["brokers"],
  mixins: [mixin],
  data() {
    return {};
  },

  mounted() {},
  methods: {}
};
</script>