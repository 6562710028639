
<template>
  <p>Logining out!...</p>
</template>
<script>
import store from "../store";
import axios from "axios";
export default {
  mounted() {
    this.logout();
  },

  methods: {
    logout() {
      axios
        .get(process.env.VUE_APP_API_URL+"/api/logout", {
          headers: { Authorization: "bearer " + localStorage.getItem("token") }
        })
        .then(response => {
          // login user, store the token and redirect to dashboard
          store.state.user={};
          localStorage.removeItem("token");
          store.commit("logoutUser");
          this.$router.push({ name: "login" });
        })
        .catch(error => {
          
          localStorage.removeItem("token");
          store.commit("logoutUser");
          this.$router.push({ name: "login" });
          console.log(error);
        });
    }
  }
};
</script>