<template>
  <div class="content" v-if="scheme">
    <div class="columns">
      <div class="column" v-if="scheme.settings">
        <p
          class="title p-md is-size-4"
          v-bind:style="{ background: scheme.settings.theme['background_color'] , color: scheme.settings.theme['text_color']  }"
        >{{scheme.name}}</p>
      </div>
      <div class="column">
        <span class="is-size-6">{{scheme.description}}</span>
      </div>
      <div class="column has-text-centered">Version: {{scheme.revision}}</div>
    </div>

    <b-tabs v-model="activeTab" expanded>
      <b-tab-item label="Fees">
        <vue-scrollbar classes="my-scrollbar" ref="Scrollbar">
          <div class="scroll-me">
            <div class="columns is-marginless">
              <div class="column is-2 is-marginless">Status</div>
              <div class="column is-1 is-marginless">Title</div>
              <div class="column is-marginless">Description</div>
              <div class="column is-1 is-marginless">Type</div>
              <div class="column is-1 is-marginless">Amount</div>
              <div class="column is-marginless"></div>
            </div>
            <hr class="is-marginless">
            <hr class="is-marginless">
            <broker-fee-component
              v-for="(fee,fee_index) in fees"
              :key="fee_index"
              :fee="fee"
              @update="UpdateFee($event,fee_index)"
            ></broker-fee-component>
            <div class="clearfix"></div>
          </div>
        </vue-scrollbar>
      </b-tab-item>
    </b-tabs>
  </div>
</template>


<script>
import mixin from "../../mixin";
import store from "../../store";
import axios from "axios";
import slugify from "@sindresorhus/slugify";
export default {
  mixins: [mixin],
  data() {
    return {
      pre_populate: false,
      scheme: {},
      activeTab: 0,
      modal_height: 0,
      array_fee: [],
      fees: []
    };
  },
  watch: {
    scheme_id() {
      this.GetbrokerScheme();
    }
  },
  computed: {
    scheme_id() {
      return this.$route.params.id;
    }
  },
  mounted() {
    this.GetbrokerScheme();
   // this.getBrokerFees(this.$route.params.broker_id);
  },
  methods: {
    UpdateFee(fee, index) {
     // console.log(fee);
      this.$set(this.fees, index, fee);
    },

    GetbrokerScheme() {
      let _self = this;
      let broker_id = this.$route.params.broker_id;
      let scheme_code = this.$route.params.scheme_code;
      //console.log();
      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/broker/" +
            broker_id +
            "/scheme/"+slugify(scheme_code),
            
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            }
          }
        )
        .then(response => {
           console.log( _self.scheme );
          _self.scheme = response.data.scheme;
           _self.fees = response.data.fees;
         
        })
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.removeItem("token");
            store.commit("logoutUser");
            _self.$router.push({ name: "login" });
          }
        });
    },
    goto(name, params) {
      this.$router.push({ name: name, params: params });
    }
  }
};
</script>