import store from "./store";
import axios from "axios";
import slugify from "@sindresorhus/slugify";

export default {
    methods: {
        slug(word) {
            return slugify(word);
        },
        getInsurerFees(id) {
            let _self = this;
            axios
                .get(process.env.VUE_APP_API_URL + "/api/insurer/" + id + "/fees", {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    }
                })
                .then(response => {
                    _self.fees = response.data;
                    _self.insurer_fees_loaded = 1;
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        store.commit("logoutUser");
                        _self.$router.push({
                            name: "login"
                        });
                    }
                });
        },
        categorization(policies) {
            let categories = { bound: [], quote: [], referred: [], accepted: [], declined: [] };
            for (let policy_index in policies) {

                let policy = policies[policy_index];
                if (typeof policy.status != 'undefined') {
                    if (policy.status == "bound") {
                        categories.bound.push(policy);
                    } else if (policy.status == "quote") {
                        categories.quote.push(policy);
                    } else if (policy.status == "referred") {
                        categories.referred.push(policy);
                    } else if (policy.status == "accepted") {
                        categories.accepted.push(policy);
                    } else if (policy.status == "declined") {
                        categories.declined.push(policy);
                    }
                }
            }
            return categories;
        },
        GetPolicyDocument(id, view_name) {

            axios({
                    method: "get",
                    url: process.env.VUE_APP_API_URL +
                        "/api/policy/" +
                        id +
                        "/document/" +
                        view_name,
                    responseType: 'blob',
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                        'Access-Control-Allow-Origin': '*',
                    }
                })
                .then(response => {
                    const file = new Blob([response.data], { type: "application/pdf" });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        store.commit("logoutUser");
                        this.$router.push({
                            name: "login"
                        });
                    }
                });
        },
        GetPolicies() {
            let _self = this;
            let URL = '';
            if (this.$route.params.insurer_id) {

                URL = process.env.VUE_APP_API_URL + "/api/insurer/" + this.$route.params.insurer_id + "/policy";
            } else if (this.$route.params.broker_id)

                URL = process.env.VUE_APP_API_URL + "/api/broker/" + _self.$route.params.broker_id + "/policy";

            console.log(URL);
            axios
                .get(URL, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    }
                })
                .then(response => {
                    _self.policies = response.data;
                    console.log("here!");
                    _self.categories = _self.categorization(response.data);

                })
                .catch(error => {
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        store.commit("logoutUser");
                        _self.$router.push({ name: "login" });
                    }
                });
        },
        GetCategory(category) {
            let _self = this;

            let URL = process.env.VUE_APP_API_URL + "/api/policy/category/" + category;
            if (this.$route.params.broker_id)

                URL = process.env.VUE_APP_API_URL + "/api/broker/" + this.$route.params.broker_id + "/policy/category/" + category;


            this.loading = true;
            axios({
                    method: "post",
                    url: URL,
                    data: {},
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    }
                })
                .then(function(response) {
                    _self.category_data = response.data;
                    _self.loading = false;
                })
                .catch(error => {
                    _self.loading = false;
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        store.commit("logoutUser");
                        _self.$router.push({
                            name: "login"
                        });
                    }

                    let response_errors = error.response.data.errors;
                    console.log(response_errors);
                    if (typeof response_errors == "undefined") return;
                    _self.errors.record(response_errors);
                });


        },
        GetLocalBroker(broker_id) {

            for (let insuer in store.state.user.insurers) {
                //console.log(store.state.user.insurers[insuer].brokers);
                for (let broker in store.state.user.insurers[insuer].brokers) {
                    if (store.state.user.insurers[insuer].brokers[broker].id == broker_id) {

                        return store.state.user.insurers[insuer].brokers[broker].name;
                    }
                }

            }
        },



        GetAccessLevel(broker_id) {
            for (let insuer in store.state.user.insurers) {
                //console.log(store.state.user.insurers[insuer].brokers);
                for (let broker in store.state.user.insurers[insuer].brokers) {
                    if (store.state.user.insurers[insuer].brokers[broker].id == broker_id) {

                        return 'insurer';
                    }
                }

            }

            for (let broker in store.state.user.brokers) {

                if (store.state.user.brokers[broker].id == broker_id) {
                    return 'broker';
                }
            }

            return 'unauthorized';
        },
        goto(name, params) {
            this.$router.push({
                name: name,
                params: params
            });
        },
        getBroker(id) {
            let _self = this;
            axios
                .get(process.env.VUE_APP_API_URL + "/api/broker/" + id, {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    }
                })
                .then(response => {
                    _self.broker = response.data;
                    _self.broker_loaded = 1;
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        store.commit("logoutUser");
                        _self.$router.push({
                            name: "login"
                        });
                    }
                });
        },
        getBrokerFees(id) {
            let _self = this;
            axios
                .get(process.env.VUE_APP_API_URL + "/api/broker/" + id + "/fees", {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    }
                })
                .then(response => {
                    _self.fees = response.data;
                    _self.broker_fees_loaded = 1;
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        store.commit("logoutUser");
                        _self.$router.push({
                            name: "login"
                        });
                    }
                });
        },
        getUser() {
            axios
                .get(process.env.VUE_APP_API_URL + "/api/user", {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    }
                })
                .then(response => {
                    //console.log(response.data);
                    store.state.user = response.data;
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        store.commit("logoutUser");
                        this.$router.push({
                            name: "login"
                        });
                    }
                });
        },
        submitLogin(email, password) {
            store.state.user = {};

            let _self = this;
            _self.error = null;
            axios
                .post(process.env.VUE_APP_API_URL + "/api/auth/login", {
                    email: email,
                    password: password
                })
                .then(response => {
                    // login user, store the token and redirect to dashboard
                    //console.log(response.data);
                    store.commit("loginUser");

                    localStorage.setItem("token", response.data.access_token);

                    _self.getUser();
                    // _self.userAssets();
                    _self.$router.push({
                        name: "home"
                    });

                })
                .catch(error => {
                    if (error.response.status === 401) {
                        _self.error = "Invalid username or password.";
                    }
                });
        }
    }
}