<template>
  <div>
    <div class="columns is-variable is-multiline">
      <div class="column is-2-desktop is-3-tablet" v-for="(zone,index) in zones" :key="index">
        <div class="is-left">
          <label class="label">{{zone}}</label>
        </div>
        <div class="field">
          <div class="control">
            <input
              type="text"
              class="input"
              v-model="values[zone]"
              :disabled="readonly"
              onclick="this.select();"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="feild has-text-centered">
      <p class="  is-size-3" :class=" total!=100 ? 'has-text-danger' : 'has-text-success'  " >%{{total}}</p>
      <p class="help has-text-danger" v-if="errors && errors['total']">{{errors['total']}}</p>
 
    </div>
  </div>
</template>



<script>
import numeral from "numeral";

export default {
  props: ["zones", "answer", "errors", "mode"],
  data() {
    return {
      values: {
        ready: false
      }
    };
  },
  computed: {
    total() {
      let total = 0;
      for (let zone in this.zones) {
        total += parseInt(this.values[this.zones[zone]]);
      }
      return total;
    },
    readonly() {
      if (this.mode && this.mode == "view") {
        return true;
      } else return false;
    }
  },
  watch: {
    values: {
      immediate: true, // so this runs initially
      deep: true, // so it detects changes to properties only
      handler(newVal, oldVal) {
        this.setzone();
      }
    }
  },
  mounted() {
    this.values = this.prepare();
  },
  methods: {
    setzone() {
      let zones_split = {};
      for (let zone in this.zones) {
        //if(this.values[this.zones[zone]]=='')this.values[this.zones[zone]]=0;

        this.values[this.zones[zone]] = Math.abs(
          numeral(this.values[this.zones[zone]]).value()
        );

        if (this.values[this.zones[zone]] > 100)
          this.values[this.zones[zone]] = this.values[this.zones[zone]] % 100;
        zones_split[this.zones[zone]] = parseInt(this.values[this.zones[zone]]);
      }
      this.$emit("input", zones_split);
    },
    prepare() {
      let values = {};

      for (let zone in this.zones) {
        if (this.answer != null && typeof !isNaN(this.answer[this.zones[zone]]))
          values[this.zones[zone]] = this.answer[this.zones[zone]];
        else values[this.zones[zone]] = 0;
      }
      this.setzone();
      this.ready = true;
      return values;
    }
  }
};
</script>