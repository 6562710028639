<template>
  <section v-if="elements">
    <span v-show="main_error" class="help has-text-danger">{{main_error}}</span>
    <div v-for="(element,index) in elements" :key="index">
      <question-component
        v-if="Object.keys(element).length !==0"
        :question="element"
        :mode="mode"
        :remark="(remark && remark[element.id])?  remark[element.id] : []"
        :answer="(answer!=null && typeof answer[element.id]!='undefined') ? answer[element.id] : null"
        :errors="(errors!=null && errors[element.id]) ? errors[element.id] : null"
        @input="setInput($event,index)"
      ></question-component>
    </div>
  </section>
</template>



<script>
import axios from "axios";
import Vue from "vue";

export default {
  props: ["elements", "answer", "errors","mode","remark"],
  data() {
    return {
      inputs: {},
      main_error: null,
      elements_error: {}
    };
  },
  watch: {
    errors(val) {
        this.main_error = null;
      if(val!=null)
      {
      
        this.elements_error = {};
        if (typeof val[Object.keys(val)[0]] != "undefined") {
          if (typeof val[Object.keys(val)[0]] != "object") {
            this.main_error = val[Object.keys(val)[0]];
          } else {
            this.elements_error = val[Object.keys(val)[0]];
          }
        }
      }

    }
  },
  created() {
    //console.log(this.answer);
    this.setup();
  },
  methods: {
    setup() {
      if (this.answer == null) {
        this.$emit("input", this.inputs);
      }
    },
    setInput(value, index) {
      let question_id = Object.keys(value)[0];
      this.inputs[question_id] = value[question_id];
      this.$emit("input", this.inputs);
    }
  }
};
</script>