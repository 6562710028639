<template>
  <section>
    <b-field label v-if="occupations">
      <b-autocomplete
        v-model="name"
        :data="filteredDataArray"
        placeholder="Software Engineer"
        icon="magnify"
        :disabled="readonly"
         :size="'is-small'"
        @select="option => selected = option"
      >
        <template slot="empty">No results found</template>
      </b-autocomplete>
    </b-field>
    <span v-show="errors" class="help has-text-danger">{{errors}}</span>
  </section>
</template>
<script>
import store from "../../store";

export default {
  props: ["answer", "errors", "mode"],
  mounted() {
    if (this.answer) this.name = this.answer;
    console.log(this.answer);
    this.setup();
  },
  data() {
    return {
      names: [],
      name: '',
      selected: null
    };
  },
  watch: {
    selected(val) {
      this.$emit("input", val);
    },
    occupations()
    {
      this.setup();
    }

  },
  methods:
  {
     setup()
     {
        let names=[];
        for (let n in this.occupations) {
          names.push(this.occupations[n].name);
        }
        this.names=names;
        
     }
  },
  computed: {
    occupations()
    {
      return store.state.scheme.occupations;
    },
    filteredDataArray() {
      if(typeof this.name.toLowerCase !='function')return [];
      return this.names.filter(option => {
        return (
          option
            .toString()
            .toLowerCase()
            .indexOf(this.name.toLowerCase()) >= 0
        );
      });
    },
    readonly() {
      if (this.mode && this.mode == "view") {
        return true;
      } else return false;
    }
  }
};
</script>