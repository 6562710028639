<template>
  <section>
      <input class="input is-small" type="text" v-model="value" :disabled="readonly">
  </section>
</template>



<script>
import axios from "axios";
import numeral from "numeral";

export default {
  props: ["answer", "errors" ,"mode"],
  data() {
    return {
      ready: false,
      value: null
    };
  },
  computed: {
            type() {
      if (this.errors) {
        return "is-danger";
      }
      return "";
    },
    readonly() {
      if (this.mode && this.mode == "view") {
        return true;
      } else return false;
    }
  },
  watch: {
    value(val) {

      this.value = numeral(this.value).format();
      let numeric = numeral(this.value).value();
      if (val == 0) this.$emit("input", 0);
      else if (this.ready) this.$emit("input", numeric);
 


    }

  },
  mounted() {
    if (this.answer != null) this.value = this.answer;
    this.ready = true;
  }
};
</script>