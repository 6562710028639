
export default {

  data() {
    return {
      preset: [
        {
          scheme: { id: 1, code: "BIA/BI" },
          question: {
            "1": {
              "233285a0-0686-11e9-a711-eb07f2bca900": "Software Developer"
            },
            "2": { "2332dd60-0686-11e9-be8e-a3c4aa8ac662": "11" },
            "3": { "23333620-0686-11e9-b8a5-d92a4c6f43b6": "1111" },
            "4": {
              "2333a360-0686-11e9-b44c-adf7992155da": {
                NSW: 0,
                QLD: 100,
                ACT: 0,
                VIC: 0,
                SA: 0,
                NT: 0,
                WA: 0,
                OS: 0
              }
            },
            "5": {
              "2333f260-0686-11e9-ada0-c3bec2924215": {
                no: {
                  "2333f400-0686-11e9-8dc0-afe154c2faa9": [
                    {
                      "2333f4a0-0686-11e9-95f9-c9e89b5ec803": {
                        yes: {
                          "2333f510-0686-11e9-9290-d10f191eb49b": {
                            "2333f590-0686-11e9-bf98-f5354831af52": { yes: {} },
                            "2333f700-0686-11e9-a254-85580f727435": { no: {} },
                            "2333f800-0686-11e9-9a14-7b38c933015f": "111",
                            "2333f880-0686-11e9-8935-23e828aae605": "100002",
                            "2333f8e0-0686-11e9-a4cb-b955130e751c": {
                              Combustible: {}
                            }
                          }
                        }
                      },
                      "2333f960-0686-11e9-b798-637b0f0a6040": "coomera",
                      "2333f9e0-0686-11e9-aed8-f1d7e763806d": "100002",
                      "2333fa40-0686-11e9-9327-8d90426d3f09": { no: {} }
                    }
                  ]
                }
              }
            },
            "6": { "233444e0-0686-11e9-b4f9-5d51e05a2478": 10000000 },
            "7": { "233555e0-0686-11e9-b4f9-5d51e05a7574": "A" },
            "8": { "233885e0-0686-11e9-b4f9-5d51e05a7334": "A" },
            "9": { "226565e0-0686-11e9-b4f9-5d51e05a3373": 100000 },
            "10": { "782165e0-0686-11e9-b4f9-5d51e05a6341": 1000000 },
            "11": { "784323e0-0686-11e9-b4f9-5d51e05a6987": "NA" },
            "13": { "785234e0-0686-11e9-b4f9-5d51e05a2769": "NA" },
            "14": { "785234e8-0686-11e9-b4f9-8d51e05a2768": "A" },
            "15": { "2333a360-0686-11e9-bbbc-adf7992155da": { yes: {} } }
          },
          broker_id: 1,
          component: {
            BP: { value: "A", enable: true },
            ED: { value: null, enable: false },
            GL: { value: "A", enable: true },
            GP: { value: "A", enable: true },
            ML: { value: 1000000, enable: true },
            TA: { value: null, enable: false },
            CYB: { value: 100000, enable: true },
            PPL: { value: 10000000, enable: true }
          },
          broker_fees: { manual: [], preset: [] }
        },
        {
          scheme: { id: 2 },
          question: {
            "1": {
              "233285a0-0686-11e9-a711-eb07f2bca900": true
            },
            "2": {
              "233285a0-0686-11e9-a711-eb07f2bca900": "Marketstall"
            },
            "3": {
              "23333620-0686-11e9-b8a5-d92a4c6f43b6": "2019-04-24"
            },
            "4": {
              "23333620-0686-11e9-b8a5-d92a4c6f43b6": "2019-04-24"
            },
            "5": {
              "23333620-3386-11e9-b8a5-d92a4c6f43b6": "Reza Torabi"
            },
            "6": {
              "23333620-0946-11e9-b8a5-d92a4c6f43b6": "1"
            },
            "7": {
              "785234e0-0686-11e9-b4f9-5d51e05a2769": 5000000
            },
            "8": {
              "7ee234e0-0686-11e9-b4f9-5d51e05a2769": "1"
            },
            "9": {
              "2333a360-0686-11e9-b44c-adf7992155da": {
                NSW: 0,
                QLD: 100,
                ACT: 0,
                VIC: 0,
                SA: 0,
                NT: 0,
                WA: 0,
                OS: 0
              }
            },
            "10": {
              "2333a360-0686-11e9-b55c-adf7992155da": {
                no: {}
              }
            },
            "11": {
              "2333a360-0686-11e9-b66c-adf7992155da": {
                yes: {}
              }
            },
            "12": {
              "2333a360-0686-11e9-b77c-adf7992155da": {
                no: {}
              }
            },
            "13": {
              "2333a360-0686-11e9-b88c-adf7992155da": {
                yes: {}
              }
            },
            "14": {
              "2333a360-0686-11e9-b99c-adf7992155da": {
                no: {}
              }
            },
            "15": {
              "2333a360-0686-11e9-baac-adf7992155da": {
                yes: {}
              }
            },
            "16": {
              "2333a360-0686-11e9-bbbc-adf7992155da": {
                no: {}
              }
            }
          }
        }
      ]
    };
  } 
}