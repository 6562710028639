<template>
  <div class="content">
    <div class="card is-fullheight">
      <div class="card-content">
        <b-table
          :data="policies"
          :bordered="false"
          :loading="loading"
          :striped="false"
          :narrowed="true"
          :hoverable="false"
          :focusable="false"
          :centered="false"
          :mobile-cards="true"
        >
          <template slot-scope="props">
            <b-table-column field="id">
              <b-button
                type="is-primary"
                size="is-small"
                @click="$emit('showPolicy',{id: props.row.id})"
              >view</b-button>
            </b-table-column>
            <b-table-column field="application.scheme.code" label="Scheme">
              <scheme-badge-component :size="'small'" :scheme="props.row.scheme"></scheme-badge-component>
            </b-table-column>
            <b-table-column
              field="policy_number"
              label="Policy Number"
            >{{ props.row.policy_number }}</b-table-column>
            <b-table-column field="quote_number" label="Quote Number">{{ props.row.quote_number }}</b-table-column>
            <b-table-column field="status" label="Status">
              <span
                class="tag is-size-7 has-text-white"
                :class="{ 'has-background-info':props.row.status=='quote' ,
                          'has-background-warning':props.row.status=='referred',
                          'has-background-danger':props.row.status=='declined',
                          'has-background-link':props.row.status=='accepted',
                          'has-background-success':props.row.status=='bound',
              }"
              >{{props.row.status }}</span>
            </b-table-column>
            <b-table-column field="type" label="Type">
              <span class="tag is-size-7">{{props.row.type }}</span>
            </b-table-column>
            <b-table-column field="broker_id" label="Broker" v-if="access=='insurer'">
              <span class="tag is-size-7">{{ GetLocalBroker( props.row.broker_id) }}</span>
            </b-table-column>
          </template>

          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <b-icon icon="emoticon-sad" size="is-large"></b-icon>
                </p>
                <p>Nothing here.</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>



<script>
import PolicyComponent from "../../views/Policy";
import store from "../../store";
import mixin from "../../mixin";
import axios from "axios";

export default {
  props: ["access","policies"],
  components: {
    PolicyComponent
  },
  mixins: [mixin],
  data() {
    return {
     
      loading: false
    };
  },

  mounted() {

  }
};
</script>