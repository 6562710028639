<template>
  <section>
    <b-field >
      <b-input
        is-danger
        type="textarea"
        v-model="description"
        placeholder="Maxlength automatically counts characters"
      ></b-input>

    </b-field>
          <div class="buttons">
        <b-button type="is-success is-small" @click="$emit('updated',{'mode':mode,'description':description})">Submit</b-button>
        <b-button type="is-danger is-small" @click="$emit('cancelled')">Cancel</b-button>
      </div>
  </section>
</template>


<script>
export default {
  props: ["referral", "mode"],

  data() {
    return {
      description:null,
    };
  },
  mounted() {},
  methods: {}
};
</script>