<template>
  <section v-if="actions">
    <section>
      <div class="block">
        <b-radio
              :class="{'is-danger':errors!=null}"
          v-for="(act,act_index) in value"
          :key="act_index"
          :size="'is-small'"
          :disabled="readonly" 
          v-model="radiochoice"
          :native-value="value[act_index]"
        >{{act}}</b-radio>
      </div>
          <p class="help is-danger" v-if="errors  && typeof errors != 'object'">{{errors}}</p>
    </section>

    <span v-show="option_error" class="help has-text-danger">{{option_error}}</span>
    <section v-for="(action,index) in actions" :key="index">
      <question-component
        v-if="Object.keys(action).length !==0 && radiochoice==index"
        :errors="(errors!=null && errors[radiochoice] && errors[radiochoice][action.id]) ? errors[radiochoice][action.id] : null"
        :question="action"
        :mode="mode"
        :remark="(remark && remark[radiochoice] && remark[radiochoice][action.id])?  remark[radiochoice][action.id] : []"
        :answer="(answer && answer[radiochoice]) ? answer[radiochoice][action.id] : null"
        @input="setQuestion"
      ></question-component>
    </section>
  </section>
</template>



<script>
import axios from "axios";
import Vue from "vue";


export default {
  props: ["actions", "answer", "errors", "mode","remark"],
  data() {
    return {
      radiochoice: null,
      sub_questions_errors: {},
      option_error: null,
      value: []
    };
  },
  computed: {
    readonly() {
      if (this.mode && this.mode == "view") {
        return true;
      } else return false;
    }
  },
  watch: {
    radiochoice(val) {
      this.setInput({});
    },

    errors(val) {
      this.sub_questions_errors = {};
      this.option_error = null;
      if (val != null) {
        if (typeof val[this.radiochoice] != "undefined") {
          if (typeof val[this.radiochoice] == "object") {
            this.sub_questions_errors = val[this.radiochoice];
          }
        } else this.option_error = val;
        // console.log(this.sub_questions_errors);
        //   this.sub_questions_errors =
        //     val[Object.keys(val)[0]][this.question_set.questions.id];
        // if (typeof val[Object.keys(val)[0]]["set"] != "undefined")
        //   this.set_errors = val[Object.keys(val)[0]]["set"];
      }
    }
  },
  created() {
    for (let a in this.actions) {
      this.value.push(a);
      if (this.answer != null && typeof this.answer[a] != "undefined") {
        this.radiochoice = a;
      }
    }
    // console.log(this.answer);
    this.value = this.value.sort();
    //console.log(this.value);
  },
  methods: {
    setInput(val) {
                        console.log("radio! input");
      console.log(val);
      // console.log("--------->");
      // console.log(Object.getOwnPropertyNames(val).length);
      // console.log("<------------------");
      if (typeof val != "object") return;
      let answer = {};
      answer[this.radiochoice] = val;

      this.$emit("input", answer);
    },
    setQuestion(val) {

      let question_id = Object.keys(val)[0];
      let answer = {};
      answer[question_id] = val[question_id];
      this.setInput(answer);
    }
  }
};
</script>