<template>
  <section class="section">
    <nav-bar></nav-bar>
    <active-view></active-view>

  </section>
</template>

<script>
import Vue from "vue";

import store from "./store";

export default {
  data() {
    return {
      show_sidebar: false
    };
  },

  computed: {
    isLoggedIn() {
      return store.state.isLoggedIn;
    }
  },
  mounted() {},
  methods: {
    toggle_sidebar(val) {
      console.log(val);
      this.show_sidebar = val;
    }
  }
};
</script>
