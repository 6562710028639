import Vue from 'vue'


import Buefy from 'buefy'
import VueScrollbar from 'vue2-scrollbar';
import VueSlimScroll from 'vue-slimscroll';
import Tooltip from "vue-bulma-tooltip";
import AnimatedVue from 'animated-vue';

import 'vue2-scrollbar/dist/style/vue2-scrollbar.css'




import 'material-design-icons-iconfont/dist/material-design-icons.css'

import './styles/spacing.scss'
import './styles/all.scss'

import 'buefy/dist/buefy.css'
import './filter.js'

Vue.use(Buefy)
Vue.use(VueSlimScroll)


import App from './App.vue'
import router from './routes.js';
import SchemeTileComponent from "./components/scheme/SchemeTile";
import SchemeBadgeComponent from "./components/scheme/SchemeBadge";

import PolicyComponent from "./views/Policy";
import BrokerFeeInputComponent from "./components/broker/BrokerFeeInput";
import BrokerNavigationComponent from "./components/broker/Navigation";
import BrokerFees from "./components/broker/Fees";
import BrokerFee from "./components/broker/Fee";
import BrokerQuote from "./components/broker/Quote";
import PriceBox from "./components/broker/PriceBox";
import SelectScheme from "./components/broker/SelectScheme";
import Steps from "./components/broker/Steps";

import InsurerNavigationComponent from "./components/insurer/Navigation";
import InsurerPolicyCategoryComponent from "./components/insurer/Category";
import PoliciesListViewComponent from "./components/insurer/List";
import ApplicationComponent from "./components/policy/Application";


import PolicyOverviewComponent from "./components/policy/Overview";
import PolicyReferralsComponent from "./components/policy/Referrals";
import QuestionComponent from "./components/question/Question.vue";
import QuestionSetComponent from "./components/question/QuestionSet";

import InsurerFeeComponent from './components/insurer/Fee'
import InsurerSchemeComponentsComponent from './components/insurer/Components'
import InsurerOccupationsComponent from './components/insurer/Occupations'

import NumberInputComponent from "./components/inputs/Number";
import CurrencyInputComponent from "./components/inputs/Currency";
import TextInputComponent from "./components/inputs/Text";
import AddressInputComponent from "./components/inputs/Address";
import RadioInputComponent from "./components/inputs/Radio";
import AdditiveInputComponent from "./components/inputs/Additive";
import CollectionInputComponent from "./components/inputs/Collection";
import OccupationInputComponent from "./components/inputs/Occupation";
import ComponentInputComponent from "./components/inputs/Component";
import StateInputComponent from "./components/inputs/State";
import CheckboxInputComponent from "./components/inputs/Checkbox";
import DateInputComponent from "./components/inputs/Date";
import EmailInputComponent from "./components/inputs/Email";
import MobileInputComponent from "./components/inputs/Mobile";
import LongtextInputComponent from "./components/inputs/Longtext";


import FooterBar from "./layout/FooterBar";
import NavBar from "./layout/Navbar";
import Sidebar from "./layout/Sidebar";
import ActiveView from "./layout/ActiveView";


// import BindingComponent from "./components/policy/Binding";
//import GlobalTaxBreakdownComponent from "./components/breakdown/GlobalTaxBreakdown";
//import ZoneTaxBreakdownComponent from "./components/breakdown/ZoneTaxBreakdown";
//import PresetBrokerFeeBreakdownComponent from "./components/breakdown/PresetBrokerFeeBreakdown";
//import ManualBrokerFeeBreakdownComponent from "./components/breakdown/ManualBrokerFeeBreakdown";
//import QuoteBreakdownSummaryComponent from "./components/breakdown/QuoteBreakdownSummary";
//import QuoteBreakdownComponent from "./components/breakdown/QuoteBreakdown";
//import SchemeComponentBreakdownComponent from "./components/breakdown/ComponentBreakdown";
//import SchemeFeeBreakdownComponent from "./components/breakdown/SchemeFeeBreakdown";
// import QuoteResultComponent from "./components/question/QuoteResult";

Vue.component("side-bar", Sidebar);
Vue.component("active-view", ActiveView);
Vue.component("nav-bar", NavBar);
Vue.component("footer-bar", FooterBar);


Vue.component("tooltip", Tooltip);
Vue.component('scheme-tile-component', SchemeTileComponent)
Vue.component("scheme-badge-component", SchemeBadgeComponent)

Vue.component("broker-fee-input-component", BrokerFeeInputComponent)
Vue.component("broker-navigation-component", BrokerNavigationComponent)
Vue.component("broker-fee-component", BrokerFee)
Vue.component("broker-fees-component", BrokerFees)
Vue.component("broker-quote-component", BrokerQuote)
Vue.component("insurer-navigation-component", InsurerNavigationComponent)
Vue.component("insurer-policy-category-component", InsurerPolicyCategoryComponent)
Vue.component("application-component", ApplicationComponent)

Vue.component("policy-overview-component", PolicyOverviewComponent)
Vue.component("policy-referrals-component", PolicyReferralsComponent)
Vue.component("question-component", QuestionComponent)
Vue.component("question-set-component", QuestionSetComponent)

Vue.component("policies-list-view-component", PoliciesListViewComponent)
Vue.component("insurer-fee-component", InsurerFeeComponent)
Vue.component("insurer-scheme-components-component", InsurerSchemeComponentsComponent)
Vue.component("insurer-occupations-component", InsurerOccupationsComponent)
Vue.component("vue-scrollbar", VueScrollbar)
Vue.component("policy-component", PolicyComponent)
Vue.component("price-box-component", PriceBox)
Vue.component("select-scheme-component", SelectScheme)
Vue.component("steps-component", Steps)



Vue.component("number-input-component", NumberInputComponent)
Vue.component("text-input-component", TextInputComponent)
Vue.component("address-input-component", AddressInputComponent)
Vue.component("currency-input-component", CurrencyInputComponent)
Vue.component("radio-input-component", RadioInputComponent)
Vue.component("additive-input-component", AdditiveInputComponent)
Vue.component("collection-input-component", CollectionInputComponent)
Vue.component("occupation-input-component", OccupationInputComponent)
Vue.component("state-input-component", StateInputComponent)
Vue.component("component-input-component", ComponentInputComponent)
Vue.component("checkbox-input-component", CheckboxInputComponent)
Vue.component("date-input-component", DateInputComponent)
Vue.component("mobile-input-component", MobileInputComponent)
Vue.component("email-input-component", EmailInputComponent)
Vue.component("longtext-input-component", LongtextInputComponent)

//Vue.component("quote-breakdown-component", QuoteBreakdownComponent)
//Vue.component("scheme-component-breakdown-component", SchemeComponentBreakdownComponent)
//Vue.component("global-tax-breakdown-component", GlobalTaxBreakdownComponent)
//Vue.component("zone-tax-breakdown-component", ZoneTaxBreakdownComponent)
//Vue.component("preset-broker-fee-component", PresetBrokerFeeBreakdownComponent)
//Vue.component("manual-broker-fee-component", ManualBrokerFeeBreakdownComponent)
//Vue.component("quote-breakdown-summary-component", QuoteBreakdownSummaryComponent)
//Vue.component("scheme-fee-breakdown-component", SchemeFeeBreakdownComponent)
// Vue.component("quote-result-component", QuoteResultComponent)
// Vue.component("binding-component", BindingComponent)

Vue.config.productionTip = false
Vue.config.devtools = true

new Vue({

    router,
    render: h => h(App),
}).$mount('#app')