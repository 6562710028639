import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'

Vue.use(VueRouter)

import LoginComponent from './views/Login'
import LogoutComponent from './views/Logout'
import DashboardComponent from './views/Dashboard'
import BrokerQuoteComponent from './components/broker/Quote'
import HomeComponent from './views/Home'
import ApplicationComponent from "./components/policy/Application"
import FeesComponent from './components/broker/Fees'
import PolicyComponent from './views/Policy'
import BrokerComponent from './views/Broker'
import InsurerComponent from './views/Insurer'
import PoliciesComponent from "./views/Policies";
import InsurerBrokersComponent from "./components/insurer/Brokers";
import InsurerSchemesComponent from './components/insurer/Scheme'
import BrokerSchemeComponent from './components/broker/Scheme'
import InsurerSchemeComponent from './components/insurer/Scheme'

const routes = [{
        path: '/insurer/:insurer_id/:insurer_name',
        component: InsurerComponent,
        name: 'insurer',
        props: true,
        meta: {
            requiresAuth: true
        },
        children: [{
                path: 'brokers',
                name: 'insurer.brokers',
                props: true,
                component: InsurerBrokersComponent,

            },
            {
                path: 'policies',
                name: 'insurer.policies',
                component: PoliciesComponent,

            },
            {
                path: 'policy/:id',
                name: 'insurer.policy',
                component: PolicyComponent,
                props: true,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'scheme/:id',
                name: 'insurer.scheme',
                component: InsurerSchemeComponent,

            },
            {
                path: 'schemes',
                name: 'insurer.schemes',
                component: InsurerSchemesComponent,

            },
            {
                path: 'broker/:broker_id/:broker_name',
                component: BrokerComponent,
                name: 'insurer.broker',
                props: true,
                meta: {
                    requiresAuth: true
                },
                children: [{
                        path: 'schemes',
                        name: 'insurer.broker.schemes',
                        component: BrokerQuoteComponent,

                    },
                    {
                        path: 'policy/:id',
                        name: 'insurer.broker.policy',
                        component: PolicyComponent,
                        props: true,
                        meta: {
                            requiresAuth: true
                        }
                    },
                    {
                        path: 'policies',
                        name: 'insurer.broker.policies',
                        component: PoliciesComponent,

                    }
                ]
            },

        ]
    },
    {
        path: '/broker/:broker_id/:broker_name',
        component: BrokerComponent,
        name: 'broker',
        props: true,
        meta: {
            requiresAuth: true
        },
        children: [{
                path: 'dashboard',
                name: 'broker.dashboard',
                component: DashboardComponent,
                props: true,
                meta: {
                    requiresAuth: true
                }
            },


            {
                path: 'policies',
                name: 'broker.policies',
                component: PoliciesComponent,

            },
            {
                path: 'policy/:id',
                name: 'broker.policy',
                component: PolicyComponent,
                props: true,
                meta: {
                    requiresAuth: true
                }
            },
            {
                path: 'scheme/:scheme_code',
                name: 'broker.scheme',
                component: BrokerSchemeComponent,
                props: true,
                children: [{
                        path: 'fees',
                        name: 'broker.scheme.fees',
                        component: FeesComponent,
                        props: true,
                    },
                    {
                        path: 'application',
                        name: 'broker.scheme.application',
                        component: ApplicationComponent,
                        props: true,
                    }
                ]

            },
            {
                path: 'schemes',
                name: 'broker.schemes',
                component: BrokerQuoteComponent,
            },

        ]
    },
    {
        path: '/',
        name: 'home',
        component: HomeComponent,
        meta: {
            requiresAuth: true
        }
    },


    {
        path: '/login',
        name: 'login',
        component: LoginComponent
    },


    {
        path: '/logout',
        name: 'logout',
        component: LogoutComponent,
        meta: {
            requiresAuth: true
        }
    }
]

const router = new VueRouter({
    mode: "history",
    base: '/',
    routes
})

router.beforeEach((to, from, next) => {

    // check if the route requires authentication and user is not logged in
    if (to.matched.some(route => route.meta.requiresAuth) && !store.state.isLoggedIn) {
        // redirect to login page
        next({
            name: 'login'
        })
        return
    }

    // if logged in redirect to dashboard
    if (to.path === '/login' && store.state.isLoggedIn) {
        next({
            name: 'home'
        })
        return
    }

    next();
})
export default router