<template>
  <div>
    
    <div v-if="insurer">
      <aside class="menu app-sidebar animated">
        <insurer-navigation-component :insurer="insurer"></insurer-navigation-component>
      </aside>
      <router-view :key="$route.fullPath" class="p-t-md"   style="min-height: 75vh;"></router-view>
      <footer-bar :company="insurer"></footer-bar>
    </div>
  </div>
</template>



<script>
import store from "../store";
import axios from "axios";
import mixin from "../mixin";

export default {
  mixins: [mixin],
  components: {},
  computed: {
    insurer() {
      for (let insuer_index in store.state.user.insurers) {
        if (
          this.$route.params.insurer_id ==
          store.state.user.insurers[insuer_index].id
        ) {
          return store.state.user.insurers[insuer_index];
        }
      }
      return null;
    },

  },
  data() {
    return {
      data: "nothing"
    };
  },

  mounted() {},
  methods: {}
};
</script>