<template>
  <div class="content has-text-centered">
    <h1 class="is-title is-bold">Login</h1>

    <div class="columns is-vcentered">
      <div class="column is-6 is-offset-3">
        <div class="box">
          <form v-on:submit.prevent="submitLogin(email,password)">
            <label class="label">Email</label>
            <p class="control">
              <input v-model="email" class="input" type="text" placeholder="email@example.org">
            </p>
            <label class="label">Password</label>
            <p class="control">
              <input v-model="password" class="input" type="password" placeholder="password">
            </p>

            <hr>
            <div class="field is-grouped">
              <div class="control">
                <button type="submit" class="button is-primary">Submit</button>
              </div>
              <div class="control">
                <button class="button is-default">Cancel</button>
              </div>
            </div>

            <small v-if="error" class="is-size-6 has-text-danger">{{error}}</small>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import store from "../store";
import axios from "axios";
import mixin from "../mixin"
export default {
  mixins:[mixin],
  data() {
    return {
      email: "",
      password: "",
      error: null
    };
  },
};
</script>
