import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isLoggedIn: !!localStorage.getItem('token'),
        user: {},
        application: { component: {} },
        binding: {},
        scheme: {},
    },
    mutations: {

        reset(state) {
            stateapplication = { component: {} };
            statebinding = {};
            statescheme = {};
        },
        loginUser(state) {
            state.isLoggedIn = true
        },
        logoutUser(state) {
            state.isLoggedIn = false
        },
        setQuote(state, value) {
            state.application = value;
        },
        setBinding(state, value) {
            state.binding = value;
        },
        setComponentPropery(state, component) {
            let scheme_components = state.scheme.components;
            for (let code in component) {
                for (let c in scheme_components) {

                    if (scheme_components[c]['code'] == code) {
                        if (typeof scheme_components[c]['condition']['component'] != 'undefined') {

                            for (let option in scheme_components[c]['condition']['component']) {

                                let option_value = scheme_components[c]['condition']['component'][option];
                                if (component[option]['value'] != option_value && component[code]['enable'] == true) {

                                    component[code]['enable'] = false;
                                }
                                if (component[option]['value'] == option_value && component[code]['enable'] == false) {
                                    component[code]['enable'] = true;
                                }

                            }
                        }
                    }
                }
            }
            state.application.component = component;
        }
    },
    getters: {

    }
})