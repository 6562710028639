<template>
  <section class="app-main">
    <div class="is-fluid is-marginless app-content">
      <level-bar></level-bar>
      <transition mode="out-in" enter-active-class="fadeIn" leave-active-class="fadeOut" appear>
        <router-view class="animated" :key="$route.fullPath"></router-view>
      </transition>
    </div>

  </section>
</template>

<script>
import Vue from "vue";
import Levelbar from "./Levelbar";
Vue.component("level-bar", Levelbar);

export default {
  components: {}
};
</script>


