<template>
  <vue-scrollbar classes="my-scrollbar" ref="Scrollbar">
    <div class="scroll-me">
      <div class="columns is-marginless">
        <div class="column is-2 is-marginless">Status</div>

        <div class="column is-1 is-marginless">Title</div>
        <div class="column  is-marginless">Description</div>
        <div class="column is-1 is-marginless">Type</div>
        <div class="column is-1 is-marginless">Amount</div>
        <div class="column is-marginless"></div>
      </div>
      <hr class="is-marginless">
      <hr class="is-marginless">
      <broker-fee-component
        v-for="(fee,fee_index) in fees"
        :key="fee_index"
        :fee="fee"
        @update="UpdateFee($event,fee_index)"
      ></broker-fee-component>
      <div class="clearfix"></div>
    </div>
  </vue-scrollbar>
</template>




<script>
import store from "../../store";
import axios from "axios";
import mixin from "../../mixin";
export default {
  mixins: [mixin],
  data() {
    return {
      array_fee: [],
      fees: []
    };
  },
  mounted() {
    this.getBrokerFees(this.$route.params.broker_id);
  },
  methods: {
    UpdateFee(fee, index) {
      this.$set(this.fees, index, fee);
    }
  }
};
</script>