<template>
  <div>
    <div class="tile is-ancestor">
      <div class="tile is-parent">
        <article class="tile is-child box">
          <p class="title">{{data.first_name}} {{data.last_name}}</p>
          <p class="subtitle">{{data.email}}</p>
        </article>
      </div>
    </div>
  </div>
</template>



<script>
import store from "../store";
import axios from "axios";
import mixin from "../mixin"
export default {
  mixins:[mixin],
  data() {
    return {
      data: "nothing"
    };
  },
  mounted()
  {
            axios
                .get(process.env.VUE_APP_API_URL + "/api/user", {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token")
                    }
                })
                .then(response => {
                    console.log(response.data);
                    if(response.data.brokers.length>0)
                    {
                        let broker = response.data.brokers[0];
                        this.$router.push({
                            name: 'broker.policies',params: { broker_id : broker.id ,broker_name : broker.name}
                        });
                    }
                    else if(response.data.insurers.length>0)
                    {
                        let insurer = response.data.insurers[0];
                        this.$router.push({
                            name: 'insurer.policies',params: { insurer_id : insurer.id ,insurer_name : insurer.name}
                        });
                    }
                    
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        localStorage.removeItem("token");
                        store.commit("logoutUser");
                        this.$router.push({
                            name: "login"
                        });
                    }
                });
 

  },
};
</script>