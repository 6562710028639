
<template>
  <div class="card">
    <header class="card-header is-capitalized p-sm">
      <p
        class="card-header-title p-sm has-text-white"
        :class="{ 'has-background-info':category=='quote' ,
                          'has-background-warning':category=='referred',
                          'has-background-danger':category=='declined',
                          'has-background-link':category=='accepted',
                          'has-background-success':category=='bound'            }"
      >{{category}}</p>
    </header>
    <div class="card-content p-sm">
      <vue-scrollbar classes="my-scrollbar" ref="Scrollbar" v-bind:style="{ height: category_panel_height+ 'px' }" >
        <div class="scroll-me">
          <table class="table is-bordered is-striped is-narrow is-fullwidth" style="font-size:0.7em;">
            <thead>
              <tr>
                <th>
                  <span class="truncated">Scheme</span>
                </th>
                <th style="max-width:6px;" class="p-xxs">

                </th>
                <th>
                  <span class="truncated">Policy No.</span>
                </th>
                <th>
                  <span class="truncated">Quote No.</span>
                </th>
                <th>
                  <span class="truncated">Quote Date</span>
                </th>
                <th>
                  <span class="truncated">Type</span>
                </th>
                <th v-if="access=='insurer'">
                  <span class="truncated">Broker</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(policy,policy_index) in policies">
                <tr v-if="policy.status==category" :key="policy_index">
                  <td>
  <span>{{ policy.scheme.code }} </span>
                  </td>
                  <td  class="p-xxs" :style="[{background: policy.scheme.settings.theme.background_color,color: policy.scheme.settings.theme.text_color}]" >

                  </td>
                  <td>
                    <a @click="$emit('showPolicy',{id: policy.id})" class="truncated" v-if="policy.policy_number">{{policy.policy_number}}</a>
                  </td>
                  <td>
                    <a  @click="$emit('showPolicy',{id: policy.id})" class="truncated" v-if="policy.quote_number">{{policy.quote_number}}</a>
                  </td>
                  <td>
                    <span class="truncated" v-if="policy.quote_date">{{policy.quote_date}}</span>
                  </td>
                  <td>
                    <span class="truncated" v-if="policy.type">{{policy.type}}</span>
                  </td>
                  <td v-if="access=='insurer'">
                    <span class="truncated  is-size-7">{{ GetLocalBroker(policy.broker_id) }}</span>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <div class="clearfix"></div>
        </div>
      </vue-scrollbar>
    </div>
  </div>
</template>




<script>
import store from "../../store";
import mixin from "../../mixin";
import axios from "axios";
import PolicyComponent from "../../views/Policy";
export default {
  props: ["policies", "category", "access"],
  components: {
    PolicyComponent
  },
  mixins: [mixin],
  data() {
    return {
      loading: false,
      category_data: [],
      category_panel_height: 250
    };
  },

  mounted() {

  }
};
</script>