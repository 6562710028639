<template>
  <section>
    <div class="columns is-marginless p-t-sm  is-size-7">
      <div class="column is-3 is-marginless is-paddingless">
        <div class="level is-mobile">
          <div class="level-left  is-hidden-tablet">Status</div>
          <div class="level-left ">
            <div class="block" v-if="mode=='view'">
              <span v-if="status" class="tag is-success is-small">Enabled</span>
              <span v-else-if="!status" class="tag is-danger is-small">Disabled</span>
              <div class="is-clearfix"></div>
            </div>
            <b-field v-if="mode=='edit'">
              <b-radio-button
                v-model="is_enabled"
                native-value="1"
                type="is-success"
                size="is-small"
              >
                <b-icon icon="check"></b-icon>
                <span>Enabled</span>
              </b-radio-button>

              <b-radio-button
                v-model="is_enabled"
                native-value="0"
                type="is-danger"
                size="is-small"
              >
                <b-icon icon="close"></b-icon>
                <span>Disabled</span>
              </b-radio-button>
            </b-field>
          </div>
        </div>
      </div>

      <div class="column is-3 is-marginless is-paddingless">
        <div class="level is-mobile">
          <div class="level-left is-hidden-tablet">Title</div>
          <div class="level-left">
            <span v-if="mode=='view'">{{fee.title}}</span>
            <b-field>
              <b-input size="is-small" v-model="edited_fee.title" v-if="mode=='edit'"></b-input>
            </b-field>
          </div>
        </div>
      </div>
      <div class="column is-2 is-marginless is-paddingless">
        <div class="level is-mobile">
          <div class="level-left  is-hidden-tablet">Type</div>
          <div class="level-left">
            <span v-if="mode=='view'">{{fee.type}}</span>
            <b-field v-if="mode=='edit'">
              <b-select v-model="edited_fee.type" size="is-small">
                <option value="fixed">Fixed</option>
                <option value="percentage">Percentage</option>
              </b-select>
            </b-field>
          </div>
        </div>
      </div>
      <div class="column is-2 is-marginless is-paddingless">
        <div class="level is-mobile">
          <div class="level-left is-hidden-tablet">Amount</div>
          <div class="level-left">
            <span v-if="mode=='view'">{{fee.amount}}</span>
            <b-field v-if="mode=='edit'">
              <b-input size="is-small" v-model="edited_fee.amount"></b-input>
            </b-field>
          </div>
        </div>
      </div>
      <div class="column is-2 is-marginless is-paddingless">
        <div class="level is-mobile">
          <div class="level-left is-hidden-tablet"></div>
          <div class="level-left">
            <div class="block" v-if="mode=='view'">
              <a @click="edit">
                <b-icon icon="pencil"></b-icon>
              </a>
              <span style="font-size:0.6em">edition: {{fee.revision}}</span>
            </div>
            <div class="block" v-if="mode=='edit'">
              <a @click="cancel">
                <b-icon icon="close-circle" type="is-danger"></b-icon>
              </a>
              <a @click="update">
                <b-icon icon="content-save" type="is-success"></b-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr class="is-marginless is-paddingless">
  </section>
</template>



<script>
import store from "../../store";
import axios from "axios";

export default {
  props: ["fee"],
  data() {
    return {
      mode: "view",
      is_enabled: "1",
      edited_fee: {}
    };
  },
  computed: {
    status: {
      get: function() {
        if (this.fee.status == 1) return true;
        else return false;
      },
      set: function(newValue) {
        if (newValue == true && this.fee.status != 1) {
          this.fee.status = 1;
        } else if (newValue == false && this.fee.status != 0)
          this.fee.status = 0;
        {
          // this.update();
        }
      }
    }
  },
  watch: {
    fee() {
      this.mode = "view";
    }
  },
  mounted() {
    if (this.fee.status == 1) this.status = true;
    else this.status = false;
  },
  methods: {
    edit() {
      let fee = {};
      this.is_enabled = this.fee.status == 1 ? "1" : "0";
      fee.type = this.fee.type;
      fee.amount = this.fee.amount;
      fee.description = this.fee.description;
      fee.condition = this.fee.condition;
      fee.scheme_id = this.fee.scheme_id;
      fee.id = this.fee.id;
      fee.uuid = this.fee.uuid;
      fee.title = this.fee.title;
      this.edited_fee = fee;
      this.mode = "edit";
    },
    cancel() {
      this.edited_fee = {};
      this.mode = "view";
    },
    update() {
      let _self = this;
      this.edited_fee.status = this.is_enabled == "1" ? 1 : 0;

      axios({
        method: "put",
        url:
          process.env.VUE_APP_API_URL +
          "/api/scheme/" +
          _self.fee.scheme_id +
          "/fee/" +
          _self.fee.id,
        data: _self.edited_fee,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      })
        .then(function(response) {
          _self.$emit("update", response.data);
        })
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.removeItem("token");
            store.commit("logoutUser");
            _self.$router.push({
              name: "login"
            });
          }
        });
    }
  }
};
</script>