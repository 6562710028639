<template>
  <nav class="navbar is-fixed-top" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <!-- <b-icon icon="view-dashboard" size="is-large" type="is-primary"></b-icon> -->
      <span v-if="company" class="m-l-md">
        <img v-if="company.logo" :src="company.logo" width="80" height="auto">
        <p v-else class="p-t-sm is-size-4">{{company.name}}</p>
        <div class="clearfix"></div>
      </span>
      <a
        role="button"
        class="navbar-burger"
        aria-label="menu"
        aria-expanded="false"
        :class="{'is-active': sidbar_toggle}"
        @click="toggle_sidebar"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navbarBasicExample" class="navbar-menu">
      <!-- <div class="navbar-start">
        <a class="navbar-item">

          <router-link v-if="isLoggedIn" :to="{ name: 'home' }">Insurance Broker System</router-link>
        </a>
      </div>-->

      <div class="navbar-end">
        <b-dropdown position="is-bottom-left" aria-role="list">
          <a class="navbar-item" slot="trigger" role="button">
            <span>{{user.first_name}} {{user.last_name}}</span>
            <b-icon icon="account-circle"></b-icon>
          </a>

          <template v-if="isLoggedIn">
            <b-dropdown-item aria-role="listitem" @click="goto('home',{})">
              <b-icon icon="home"></b-icon>Home
            </b-dropdown-item>
            <hr class="dropdown-divider" aria-role="listitem">
            

            <template v-for="(insurer,insurer_index) in user.insurers">
              <b-dropdown-item aria-role="listitem" has-link :key="insurer_index">
                <router-link
                  :to="{ name: 'insurer',params: { insurer_id : insurer.id ,insurer_name : insurer.name}}"
                >{{insurer.name}}</router-link>
              </b-dropdown-item>
              <hr class="dropdown-divider" aria-role="listitem">
            </template>

            <template v-for="(broker,broker_index) in user.brokers">
              <b-dropdown-item aria-role="listitem" has-link :key="broker_index">
                <router-link
                  :to="{ name: 'broker',params: { broker_id : broker.id ,broker_name : broker.name}}"
                >{{broker.name}}</router-link>
              </b-dropdown-item>
              <hr class="dropdown-divider" aria-role="listitem">
            </template>


            <hr class="dropdown-divider" aria-role="listitem">
            <b-dropdown-item>
              <b-icon icon="settings"></b-icon>Settings
            </b-dropdown-item>
            <hr class="dropdown-divider">
            <b-dropdown-item aria-role="listitem" @click="goto('logout',{})">
              <b-icon icon="logout"></b-icon>Logout
            </b-dropdown-item>
          </template>
          <template v-else>
            <b-dropdown-item aria-role="listitem">
              <b-icon icon="login"></b-icon>
              <router-link :to="{ name: 'login' }">Login</router-link>
            </b-dropdown-item>
          </template>
        </b-dropdown>
      </div>
    </div>
  </nav>
</template>

<script>
import Vue from "vue";

import store from "../store";
import mixin from "../mixin.js";

export default {

  mixins: [mixin],
  data() {
    return {
      sidbar_toggle: false
    };
  },
  methods: {
    toggle_sidebar() {
      this.sidbar_toggle = !this.sidbar_toggle;
      console.log(this.sidbar_toggle);
      this.$emit("togglesidebar", this.sidbar_toggle);
    },
    mobilemenu() {}
  },
  mounted() {
    this.getUser();
  },
  watch: {
    user(val) {
      //console.log(val);
    }
  },
  computed: {
    company()
    {
        if(this.$route.params.insurer_id)
        {
          for(let insurer_index in store.state.user.insurers)
          {
              if(store.state.user.insurers[insurer_index].id=this.$route.params.insurer_id)
              return store.state.user.insurers[insurer_index];
          }
        }
        else if(this.$route.params.broker_id)
        {
          for(let broker_index in store.state.user.brokers)
          {
              if(store.state.user.brokers[broker_index].id=this.$route.params.broker_id)
              return store.state.user.brokers[broker_index];
          }
        }
        return null;
    },
    user() {
      if (store.state.user.id) {
        return store.state.user;
      }
      //this.getUser();
      return {};
    },
    isLoggedIn() {
      return store.state.isLoggedIn;
    }
  }
};
</script>

