<template>
  <section>
   <textarea class="textarea" 
      :class="{'is-danger':errors!=null &&errors.length>0 }"
 
      v-model="value"
      :disabled="readonly"
 
    />
    <p class="help is-danger" v-if="errors!=null ">{{errors}}</p>
  </section>
</template>



<script>
import axios from "axios";

export default {
  props: ["answer", "errors", "mode"],
  data() {
    return {
      ready: false,
      value: null
    };
  },
  computed: {
 
    readonly() {
      if (this.mode && this.mode == "view") {
        return true;
      } else return false;
    }
  },
  watch: {
    value(val) {
      if (this.ready) this.$emit("input", val);
    }
  },
  created() {
    if (this.answer != null) this.value = this.answer;
    this.ready = true;
  }
};
</script>