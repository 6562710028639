<template>
  <section>
    <select-scheme-component v-if="!scheme_code" @input="loadScheme" :schemes=schemes>
      <!-- <b-checkbox v-model="pre_populate" native-value="Silver">Pre populate the Application</b-checkbox> -->
    </select-scheme-component>

    <section v-if="scheme">
      <steps-component :stages="scheme.settings.stages " :stage="stage" :current_step_index="stage_index" ></steps-component>
      <div class="columns reverse-column-order" v-if="!IsCompleted">
        <div class="column is-three-quarters-mobile is-two-thirds-tablet is-two-thirds-desktop  ">
          <div class="tile is-parent">
            <article class="tile is-child has-background-white">
              <div class="tile is-child box">
                <!-- <vue-scrollbar
                  classes="my-scrollbar"
                  ref="Scrollbar"
                  v-bind:style="{ height: modal_height*.6 + 'px' }"
                > -->
                  <div class="scroll-me">
                    <application-component
                      class="p-sm"
                      @updated="$emit('updated',$event)"
                      @SubmitApplication="SubmitApplication"
                      :scheme="scheme"
                      :errors="application_errors"
                      :stage_index="stage_index"
                      :broker_id="broker_id"
                      :stagee="stage"
                      :mode="mode"
                      :application="getPreset(scheme_code)"
                      v-if="scheme_loaded"
                    ></application-component>
                  </div>
                <!-- </vue-scrollbar> -->
              </div>
            </article>
          </div>

          <div class="field is-grouped">
            <div class="control has-text-centered">
              <button
                v-if="stage_index!=0 &&  policy.status!='accepted'"
                type="submit"
                class="button is-primary"
                @click="backStage"
              >Back</button>
            </div>

            <div class="control has-text-centered">
              <button type="submit" class="button is-primary" @click="forwardStage">Next</button>
            </div>
          </div>
        </div>
 
        <div class="column " v-if="stage=='application' &&  policy.status!='accepted'">
          <scheme-tile-component :scheme="scheme" :size="'small'"></scheme-tile-component>
          <price-box-component    :price="price" :broker_income="broker_income">
             <p class="title is-size-7 has-text-centered">Broker Income: <span  class="has-text-info">{{broker_income | currency}}</span></p>
          </price-box-component>
           <broker-fee-input-component v-if="this.scheme.settings.stages[this.stage][stage_index]=='quote'" :fees="fees" :selected_fees="broker_fee_container" @input="setBrokerFees"></broker-fee-input-component>
        </div>

      </div>
      <div v-else-if="policy.id && (policy.status=='quote' || policy.status=='referred' || policy.status=='bound')" class="has-text-centered">
        <div class="tile">
          <div class="tile is-parent" v-if="scheme.settings">
            <article class="tile is-child notification has-background-white">
              <template  v-if="policy.status=='quote'">
                <p class="is-size-7">Application is completed successfully!. Your Reference number is:</p>
                <p class="is-size-1">{{policy.quote_number}}</p>
              </template>
              <template  v-if="policy.status=='referred'">
              <p class="is-size-7" >Unfortunately we are unable quote this application immediately!. 
                We will review your application and we will advise you via e-mail          
                Your Reference number is:</p>  <p class="is-size-1">{{policy.quote_number}}</p>
              </template>
              <template  v-if="policy.status=='bound'">
              <p class="is-size-7">         
                Your Policy number is:</p>
                  <p class="is-size-1">{{policy.policy_number}}</p>
              </template>
              <div class="control has-text-centered">
                <button type="submit" class="button is-info" @click="viewApplication">View Application</button>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
    <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="true"></b-loading>
  </section>
</template>




<script>
import slugify from "@sindresorhus/slugify";
import store from "../../store";
import presets from "../../presets";
import axios from "axios";
import Errors from "../../classes/errors.js";
export default {
  props: ["broker_id","c_policy","stage","mode"],
  mixins:[presets],
  data() {
    return {
      modal_height: window.innerHeight,
      pre_populate: false,
      schemes: null,
      scheme_code: null,
      scheme: null,
      scheme_loaded: false,
      errors: new Errors(),
      application_errors: new Errors(),
      stage_index: 0,
      policy: {},

      fees: null,
      price: null,
      broker_income:null,
      manual_fee: 0,
      isLoading: false,
      IsCompleted: false,
      broker_fee_container: {
        manual_fee: 0,
        manual_fee_toggle: false,
        manual_fee_type: "fixed",
        broker_fees: []
      }
    };
  },
  created ()
  {
      window.addEventListener("resize", this.windowResized);
  },
  beforeDestroy () {
      window.removeEventListener('resize', this.windowResized)
  },

  mounted() {
      this.setup();
  },
  methods: {
    windowResized(e)
    {
      this.modal_height= window.innerHeight;
    },
    setup()
    {
          let _self = this;
    let broker_id = this.broker_id;
    
    if(this.c_policy && this.c_policy.id)
    {
      this.policy=this.c_policy;
      this.scheme=this.c_policy.scheme;
      this.scheme_code=this.c_policy.scheme.code;
      this.scheme_loaded=true;


      for(let stage in this.scheme.settings.stages[this.stage])
      {
             //   console.log("Stage");
             // console.log(this.c_policy.application.stage[this.stage]);
        if(this.scheme.settings.stages[this.stage][stage]==this.c_policy.application.stage[this.stage])
        {
          this.stage_index=stage;
          this.stage_index++;
        }
      }
    }
    else 
    axios
      .get(
        process.env.VUE_APP_API_URL + "/api/broker/" + broker_id + "/schemes",
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          }
        }
      )
      .then(response => {
        _self.schemes = response.data;
      })
      .catch(error => {
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          store.commit("logoutUser");
          _self.$router.push({ name: "login" });
        }
      });
    },
    CalculateBrokerIncome(quote)
    {
      let income =0;
      for(let c in  quote.component)
      {
        income+=quote.component[c].base;
      }
      income*=this.scheme.settings.comission;
      income+=quote.fee.broker.base;
      income = income- (income* .1);
      return income;
    },
    viewApplication()
    {
            if(this.c_policy && this.c_policy.id) 
            this.$emit('updated',this.policy); 
            else this.$emit('updated', { id: this.policy.id });
    },
    backStage() {
      if (this.stage_index > 0) {
        this.stage_index--;
     //   this.$refs.Scrollbar.scrollToY(0);
      }
    },
    forwardStage() {
     // this.$refs.Scrollbar.scrollToY(0);
      this.submit("normal", "post");
    },
    setBrokerFees(fees) {
      this.broker_fee_container = fees;
      this.submit("update", "post");
    },
    prepareBrokerFees() {
      let final_fees = { preset: [], manual: [] };

      for (let fee in this.broker_fee_container.broker_fees) {
        let new_fee = {};
        if (this.fees[fee]) {
          new_fee.id = this.fees[fee].id;
          new_fee.type = this.fees[fee].type;
          new_fee.amount = parseFloat(this.fees[fee].amount);
        }
        final_fees.preset.push(new_fee);
      }
      if (this.broker_fee_container.manual_fee_toggle) {
        let manual_fee_amount =
          this.broker_fee_container.manual_fee_type == "percentage"
            ? parseFloat(0.01 * this.broker_fee_container.manual_fee)
            : parseFloat(this.broker_fee_container.manual_fee);
        let new_fee = {};
        new_fee.type = this.broker_fee_container.manual_fee_type;
        new_fee.amount = manual_fee_amount;
        final_fees.manual.push(new_fee);
      }
      console.log(final_fees);
      return final_fees;
    },
    SubmitApplication(submition) {
      this.submit(submition.mode, submition.type);
    },
    getPreset(scheme_code) {
       if(this.c_policy && this.c_policy.id)
       {
          return this.c_policy.application;
       }
       else 
       {
          if (!this.pre_populate) return {};
          for (let preset_index in this.preset) {
            if (this.preset[preset_index].scheme.code == scheme_code) {
              return this.preset[preset_index];
            }
          }
       }

    },
    goto(name, params) {
      this.$router.push({ name: name, params: params });
    },

    loadScheme(code) {
      let broker_id = this.$route.params.broker_id;
      this.scheme_code = code;
      let sluged_scheme_code = slugify(code);
      let _self = this;

      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/broker/" +
            broker_id +
            "/scheme/" +
            sluged_scheme_code,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            }
          }
        )
        .then(response => {
          //  console.log(response.data);
          // _self.load(false);
          if ((_self.scheme = response.data.scheme)) {
            _self.fees = response.data.fees;
            _self.scheme_loaded = true;
          }
        })
        .catch(error => {
          _self.load(false);
          if (error.response && error.response.status === 401) {
            localStorage.removeItem("token");
            store.commit("logoutUser");
            _self.$router.push({ name: "login" });
          }
        });
    },

    submit(mode, trans) {
      this.isLoading = true;
      this.application_errors.clear();
      let transaction = "post";
      let URL = process.env.VUE_APP_API_URL + "/api/broker/"+this.broker_id+"/policy";
      if (this.policy && this.policy.id) {
        transaction = "put";
        URL = process.env.VUE_APP_API_URL + "/api/broker/"+this.broker_id+"/policy/" + this.policy.id+"/progress";
        //console.log(URL);
        //console.log(process.env.VUE_APP_API_URL + "/api/quote/" + this.policy.id);
      }

      let application = store.state.application;
      //console.log(application);
      application.broker_id = this.$route.params.broker_id;
      application.broker_fees = this.prepareBrokerFees();
      application.stage={};
      application.stage[this.stage] = this.scheme.settings.stages[this.stage][this.stage_index] ;
      application.mode = mode;
      let _self = this;
      // console.log(application);
      axios({
        method: transaction,
        url: URL,
        data: application,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      })
        .then(function(response) {
          console.log(response.data);
          //If we just updating the page
          if (mode != "update") _self.stage_index++;
          if (
            response.data &&
            response.data.quote &&
            response.data.quote.total
          ) {
            _self.price = response.data.quote.total;

            _self.broker_income=_self.CalculateBrokerIncome(response.data.quote);


          }else _self.price =null;

          if (response.data.policy) {
            //_self.policy = response.data.policy;
            store.commit("setQuote", {});
            //_self.goto("broker.policy", { id: response.data.policy.id });

            _self.IsCompleted = true;
            _self.policy = response.data.policy;

          }
          _self.isLoading = false;
          console.log("success");
        })
        .catch(error => {
          _self.isLoading = false;
          _self.price=null;
          console.log(error.response);
          if (error.response && error.response.status === 401) {
            localStorage.removeItem("token");
            store.commit("logoutUser");
            _self.$router.push({ name: "login" });
          } else if (error && error.response) {
            _self.errors.clear();
            let response_errors = error.response.data.errors;
            if(response_errors.application) _self.application_errors.record(response_errors.application);
            if (typeof response_errors == "undefined") return;
            if (mode != "update") _self.errors.record(response_errors);
          }
        });
    }
  }
};
</script>