<template>
  <div>
    <b-modal :active.sync="show_modal" :width="modal_width" has-modal-card>
      <div
        class="modal-card"
        ref="modalwarapper"
        v-bind:style="{ height: (modal_height -40) + 'px', width: (modal_width -40) + 'px' }"
      >
        <section class="modal-card-body">
          <broker-quote-component
            @updated="showPolicy"
            :stage="'application'"
            v-if="new_app"
            :broker_id="this.$route.params.broker_id"
          ></broker-quote-component>

          <policy-component v-if="policy_id" :id="policy_id"></policy-component>
        </section>
      </div>
    </b-modal>

    <b-field grouped>
      <p class="control" v-if="AccessLevel=='broker'">
        <b-button
          @click="show_modal=true;new_app=true;policy_id=null"
          type="is-success"
        >New Application</b-button>
      </p>
      <b-radio v-model="view_mode" native-value="category">Category</b-radio>
      <b-radio v-model="view_mode" native-value="list">List</b-radio>
    </b-field>

    <policies-list-view-component v-if="view_mode=='list'" @showPolicy="showPolicy" :policies="policies" :access="AccessLevel"></policies-list-view-component>
    <section v-if="view_mode=='category' && !loading">
      <div class="columns">
        <div class="column is-narrow is-one-third-desktop is-half-tablet">
          <insurer-policy-category-component
            category="quote"
            :policies="policies"
            @showPolicy="showPolicy"
            :access="AccessLevel"
          ></insurer-policy-category-component>
        </div>

        <div class="column is-narrow is-one-third-desktop is-half-tablet">
          <insurer-policy-category-component
            category="referred"
             :policies="policies"
            @showPolicy="showPolicy"
            :access="AccessLevel"
          ></insurer-policy-category-component>
        </div>
        <div class="column is-narrow is-one-third-desktop is-half-tablet">
          <insurer-policy-category-component
            category="accepted"
            :policies="policies"
            @showPolicy="showPolicy"
            :access="AccessLevel"
          ></insurer-policy-category-component>
        </div>
      </div>
      <div class="columns">
        <div class="column is-narrow is-one-third-desktop is-half-tablet">
          <insurer-policy-category-component
            category="declined"
            :policies="policies"
            @showPolicy="showPolicy"
            :access="AccessLevel"
          ></insurer-policy-category-component>
        </div>
        <div class="column is-narrow is-one-third-desktop is-half-tablet">
          <insurer-policy-category-component
            category="bound"
             :policies="policies"
            @showPolicy="showPolicy"
            :access="AccessLevel"
          ></insurer-policy-category-component>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import store from "../store";
import axios from "axios";
import mixin from "../mixin";

export default {
  props: ["insurer_id"],
  mixins: [mixin],
  components: {

  },
  data() {
    return {
      view_mode: "category",
      category_panel_height: 0,
      categories:{quote:[],quotes:[],referrals:[]},
      show_modal: false,
      policy_id: null,
      new_app: false,
      loading: false,
       policies: [],
      modal_height: window.innerHeight,
      modal_width: window.innerWidth 
    };
  },
  created ()
  {

      window.addEventListener("resize", this.windowResized);
  },
  beforeDestroy () {
      window.removeEventListener('resize', this.windowResized)
  },
  mounted() {
   
    this.GetPolicies();
  },
  watch: {
    AccessLevel(val) {
      return val;
    }
  },
  computed: {
    AccessLevel() {
      if (this.$route.params.insurer_id) return "insurer";
      if (this.$route.params.broker_id) {
        return this.GetAccessLevel(this.$route.params.broker_id);
      }
      return;
    }
  },
  methods: {
    windowResized(e)
    {
      //console.log(e);
      this.modal_height= window.innerHeight * 0.8;
      this.modal_width= window.innerWidth * 0.8;
    },
    showPolicy(policy) {
      console.log(policy.id);
      let _self = this;
      this.loading = true;
      this.new_app = null;
      this.policy_id = policy.id;
      this.show_modal = true;

      setTimeout(function() {
        _self.loading = false;
      }, 500);
    },


    goto(name, params) {
      this.$router.push({ name: name, params: params });
    }
  }
};
</script>