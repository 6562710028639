<template>
  <section>
    <b-field label :type="type" :message="errors">
           <b-checkbox v-model="value" :disabled="readonly"> 
                <span class="is-size-7" v-html="label"></span>
            </b-checkbox>
    </b-field>

    <div class="content is-size-7" v-if="post_description" v-html="post_description"></div>
  </section>
</template>



<script>
import axios from "axios";

export default {
  props: ["answer", "errors", "label", "mode", "post_description"],
  data() {
    return {
      ready: false,
      value: null
    };
  },
  computed: {
    type() {
      if (this.errors) {
        return "is-danger";
      }
      return "";
    },
    readonly() {
      if (this.mode && this.mode == "view") {
        return true;
      } else return false;
    }
  },
  watch: {
    value(val) {
      if (this.ready) this.$emit("input", val);
    }
  },
  created() {
    if (this.answer != null) this.value = this.answer;
    this.ready = true;
  }
};
</script>