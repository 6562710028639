<template>
  <section>
    <input
       class="input is-small"
      :class="{'is-danger':errors!=null}"
      type="text"
      v-model="value"
      :disabled="readonly"
      onclick="this.select();"
    />
    <p class="help is-danger" v-if="errors">{{errors}}</p>
  </section>
</template>



<script>
import axios from "axios";

export default {
  props: ["answer", "errors", "mode"],
  data() {
    return {
      ready: false,
      value: null
    };
  },
  computed: {
    type() {
      if (this.errors) {
        return "is-danger";
      }
      return "";
    },
    readonly() {
      if (this.mode && this.mode == "view") {
        return true;
      } else return false;
    }
  },
  watch: {
    value(val) {
      if (this.ready) this.$emit("input", val);
    }
  },
  created() {
    if (this.answer != null) this.value = this.answer;
    this.ready = true;
  }
};
</script>