<template>
  <section v-if="scheme.question_sets && setup_completed">
    <template v-for="question_set in scheme.question_sets">
      <question-set-component
        v-if="(question_set.placement[stagee] ) && (question_set.placement[stagee]==stage || (mode=='view'&& getQuestionAnswer(question_set.number)))"
        :key="question_set.id"
        :mode="mode"
        :question_set="question_set"
        :errors="getQuestionErrors(question_set.number)"
        :answer="getQuestionAnswer(question_set.number)"
        :remark="(remark && remark[question_set.id] && access=='insurer') ? remark[question_set.id] : []"
        @input="questionsetChanged($event,question_set.number)"
      ></question-set-component>
    </template>

  </section>
</template>




<script>
import store from "../../store";
import axios from "axios";
import mixin from "../../mixin";
import Errors from "../../classes/errors.js";
import slugify from "@sindresorhus/slugify";
//

export default {
  mixins: [mixin],
  props: [
    "scheme",
    "application",
    "mode",
    "remark",
    "policy",
    "access",
    "errors",
    "stage_index",
    "stagee"
  ],
  components: {},
  data() {
    return {

      submitTimer: null,
      setup_completed: false
    };
  },
  watch: {
    stage(val) {
      //console.log(val);
    }
  },
  computed: {
    stage() {
      if (
        this.scheme &&
        this.scheme.settings &&
        this.scheme.settings.stages &&
        this.scheme.settings.stages[this.stagee] &&
        this.scheme.settings.stages[this.stagee][this.stage_index]
      )
        return this.scheme.settings.stages[this.stagee][this.stage_index];
      else return null;
    }
  },
  mounted() {
    console.log(this.stage);
    this.setup();
    
  },

  methods: {
    getQuestionAnswer(number) {
      let questions = store.state.application.question;
      if (
        typeof questions == "undefined" ||
        typeof questions[number] == "undefined"
      )
        return null;
      return questions[number];
    },

    goto(name, params) {
      this.$router.push({ name: name, params: params });
    },

    questionsetChanged(answer, question_set_number) {
      clearTimeout(this.submitTimer);
      let application = store.state.application;
      let question_id = Object.keys(answer)[0];
      if (typeof application.question == "undefined") application.question = {};
      if (typeof application.question[question_set_number] == "undefined")
        application.question[question_set_number] = {};
      application.question[question_set_number][question_id] =
        answer[question_id];

      store.commit("setQuote", application);

      if (this.stage == "quote") {
        this.triggerUpdate();
      }
    },
    triggerUpdate() {
      let _self = this;
      _self.submitTimer = setTimeout(function() {
        _self.$emit("SubmitApplication", { mode: "update", type: "post" });
        //  _self.submit("update", "post");
      }, 200);
    },

    getQuestionErrors(number) {
      if (
        this.errors.has("question_set") &&
        this.errors.getArray("question_set") &&
        this.errors.getArray("question_set")[number]
      ) {
        return this.errors.getArray("question_set")[number];
      }
      return null;
    },
    setup() {
      let application = store.state.application;

      application.scheme = {};
      application.scheme.code = this.scheme.code;
      application.scheme.id = this.scheme.id;
       application.stage={};
      application.stage[this.stagee] = this.scheme.settings.stages[this.stagee][this.stage_index] ;
      application.component = {};
      application.question = {};

      if (
        this.application &&
        this.application.scheme &&
        this.scheme.code == this.application.scheme.code
      ) {
        application.question = this.application.question;

        //  console.log(this.application.question["5"]['2333f260-0686-11e9-ada0-c3bec2924215']['yes']['2333f400-0686-11e9-8dc0-afe154c2faa9'][0]['2333f4a0-0686-11e9-95f9-c9e89b5ec803']);
      }

      for (let c in this.scheme.components) {
        application.component[this.scheme.components[c].code] = {
          enable: true,
          value: ""
        };
      }

      //this.getBroker(this.$route.params.broker_id);

      application.broker_id = this.$route.params.broker_id;
      store.state.scheme = this.scheme;

      store.commit("setQuote", application);
      {
        this.setup_completed = true;
      }
    }
  }
};
</script>