<template>
  <div class="tile">
    <div class="tile is-parent" v-if="scheme.settings">
      <article class="tile is-child notification has-background-white">
        <div class="columns">
          <div class="column is-narrow  is-gapless">
          <scheme-badge-component :size="size" :scheme="scheme"></scheme-badge-component>
         </div>
            <div class="column  is-gapless ">
                <p class=" scheme-tile title" :style="[{'font-size':font_size}]">{{scheme.name}}</p>
          </div>
        </div>

        <p class="subtitle scheme-tile has-text-grey" :style="[{'font-size':font_size}]">{{scheme.description}}</p>
      </article>
    </div>
  </div>
</template>


<script>
export default {
  props: ["scheme", "size"],
  data() {
    return {
      font_size: "1em"
    };
  },
  watch: {
    size(val) {
      this.setSize();
      console.log(val);
    }
  },
  mounted() {
    this.setSize();
  },
  methods: {
    setSize() {
      console.log(this.size);
      switch (this.size) {
        case "small":
          this.font_size = "1em";
          break;
        case "large":
          this.font_size = "1.4em";
          break;
        default:
          this.font_size = "1em";
          break;
      }
    }
  }
};
</script>