<template>
      <ul class="steps has-content-centered is-small is-balanced is-horizontal">
        <li class="steps-segment" v-if="first">
          <span class="steps-marker"></span>
          <div class="steps-content">
            <p class="is-size-7 is-capitalized">{{first}}</p>
          </div>
        </li>
        <li
          class="steps-segment"
          v-for="(step, step_index) in stages[stage]"
          :key="step_index"
          :class="{ 'is-active' : stages[stage][step_index]==stages[stage][current_step_index] }"
        >
            <span class="steps-marker"></span>
            <div class="steps-content">
              <p
                class="is-size-7 is-capitalized"
              >{{stages[stage][step_index].replace('_',' ')}}</p>
            </div>
        </li>
        <li class="steps-segment" v-if="last">
          <span class="steps-marker"></span>
          <div class="steps-content">
            <p class="is-size-7 is-capitalized">{{last}}</p>
          </div>
        </li>
      </ul>
</template>




<script>
import slugify from "@sindresorhus/slugify";
import store from "../../store";
import axios from "axios";
import Errors from "../../classes/errors.js";
export default {
  props: ["stages","stage","current_step_index"],
  data() {
    return {

    }
  },
  computed:
  {
      first()
      {
          switch (this.stage)
          {
            case 'application':
              return 'scheme';
              break;
            case 'binding':
              return 'Quote';
              break;
            default:
              return null;
              break;
          }
      },
      last()
      {
          switch (this.stage)
          {
            case 'application':
              return 'Complete';
              break;
            case 'binding':
              return 'Complete';
              break;
            default:
              return null;
              break;
          }
      }
  }
};
</script>