<template>
  <div :class="{'tile is-ancestor':!readonly}">
    <div :class="{'tile is-parent':!readonly}">
      <section :class="{'tile is-child box':!readonly}" v-if="question_set">

        <question-component
          :question="question_set.questions"
          :answer="QuestionAnswer(question_set.questions.id)"
          :errors="question_errors"
          :mode="mode"
          :remark="(remark && remark[question_set.questions.id])?  remark[question_set.questions.id] : []"
          @input="setQuestion"
        ></question-component>
        <span class="has-text-danger">{{set_errors}}</span>
      </section>
    </div>
  </div>
</template>



<script>
import Errors from "../../classes/errors.js";
import axios from "axios";
import Vue from "vue";
import store from "../../store";
export default {
  props: ["question_set", "answer", "errors","mode","remark"],
  data() {
    return {
      question_errors: null,
      set_errors: null
    };
  },
  watch: {
    errors(val) {
     
      this.question_errors = null;
      this.set_errors = null;
      if (val != null) {
        if (
          typeof val[Object.keys(val)[0]][this.question_set.questions.id] !=
          "undefined"
        )
          this.question_errors =
            val[Object.keys(val)[0]][this.question_set.questions.id];
        if (typeof val[Object.keys(val)[0]]["set"] != "undefined")
          this.set_errors = val[Object.keys(val)[0]]["set"];
      }
    }
  },
  computed: {

    readonly()
    {
      if(this.mode && this.mode=='view')
      {
        return true;
      }
      else return false;
    },

    QuestionErrors() {
      if (
        this.question_set != null &&
        typeof this.question_set.questions != "undefined" &&
        typeof this.question_set.questions.id != "undefined"
      ) {
        // console.log(this.errors);
        if (
          this.errors != null &&
          typeof this.errors[this.question_set.questions.id] != "undefined"
        ) {
          return this.errors[this.question_set.questions.id];
        }
      }
      return null;
    }
  },

  methods: {
    QuestionAnswer(id) {
      if (this.answer == null || typeof this.answer[id] == "undefined")
        return null;
      return this.answer[id];
    },
    setQuestion(val) {
               //   console.log("question_set!");
      //console.log(val);

      if (val == true || val == false) return;
       this.$emit("input",val);
    }
  }
};
</script>