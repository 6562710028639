<template>
  <footer class="p-t-md has-background-white ">
    <div class="content has-text-centered">
      <p>{{company.name}} 2019</p>
    </div>
  </footer>
</template>

<script>
export default {
  props:['company']

};
</script>

