<template>
  <section v-if="elements">
    <section class="box m-h-s" v-for="(set,set_index) in sets" :key="set_index">
      <div v-for="(element,index) in elements" :key="index">
        <question-component
          v-if="Object.keys(element).length !==0"
          :question="element"
          :mode="mode"
          :remark="(remark && remark[set_index] && remark[set_index][element.id])?  remark[set_index][element.id] : []"
          :errors="elements_error[set_index] ? elements_error[set_index][element.id] : null"
          :answer="(answer!=null && typeof answer[set_index]!='undefined') ? answer[set_index][element.id] : null"
          @input="setInput($event,set_index)"
        ></question-component>
      </div>
      <div class="control m-h-s" v-if="set>1 && set==sets">
        <button type="submit" class="button is-default" @click="removeSet">-</button>
      </div>
    </section>
    <span v-show="main_error" class="help has-text-danger">{{main_error}}</span>
    <div v-if="!readonly" class="control m-h-s">
      <button type="submit" class="button is-primary" @click="addSet">+</button>
    </div>

  </section>
</template>



<script>
import axios from "axios";
import Vue from "vue";

export default {
  props: ["elements", "errors","answer","mode","remark"],
  data() {
    return {
      inputs: [],
      sets: 0,
      main_error: null,
      elements_error: {}
    };
  },
  watch: {

    errors(val) {
      this.main_error = null;
      this.elements_error = {};
      if (typeof val[Object.keys(val)[0]] != "undefined") {
        if (typeof val[Object.keys(val)[0]] != "object") {
          this.main_error = val[Object.keys(val)[0]];
        } else {
          this.elements_error = val[Object.keys(val)[0]];
        }
      }
    }

  },
  created() {
    this.setup();
  },
  computed:
  {
    readonly()
    {
      if(this.mode && this.mode=='view')
      {
        return true;
      }
      else return false;
    },
  },
  methods: {
    setup()
    {
      if(this.answer !=null)
      {
        for(let a in this.answer)
        {
            this.addSet();
        }
      }
      else
      {
        this.addSet();
      }
    },
    setInput(value, index) {
      let question_id = Object.keys(value)[0];
      if (typeof this.inputs[index] == "undefined") {
        this.inputs[index] = {};
      }
      this.inputs[index][question_id] = value[question_id];
      this.$emit("input", this.inputs);
      console.log(this.inputs);
    },
    addSet() {
      this.sets++;
      let matrix = {};
      for (let e in this.elements) {
        matrix[this.elements[e].id] = "";
      }
      this.inputs[this.sets-1]=matrix;
      this.$emit("input", this.inputs);
       console.log(this.inputs);
    },
    removeSet() {
      if (this.sets > 0) {
        this.sets--;
        this.inputs.pop();
      }
    }
  }
};
</script>

