<template>
  <section>

      <div class="tile is-ancestor">
        <div
          class="tile is-vertical is-2 scheme"
          v-for="scheme in schemes"
          :key="scheme.id"
          @click="$emit('input',scheme.code)"
        >
          <div class="tile">
            <div class="tile is-parent is-vertical" v-if="scheme.settings">
              <article
                class="tile is-child notification"
                v-bind:style="{ background: scheme.settings.theme['background_color']}"
              >
                <p
                  class="title"
                  v-bind:style="{ color: scheme.settings.theme['text_color']  }"
                >{{scheme.code}}</p>
                <p
                  class="subtitle"
                  v-bind:style="{ color: scheme.settings.theme['text_color']  }"
                >{{scheme.name}}</p>
              </article>
            </div>
          </div>
        </div>
      </div>
      <slot></slot>
    </section>
</template>




<script>


export default {
  props: ["schemes"],
  data() {
    return {

  }
}
}
</script>