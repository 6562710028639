<template>
  <aside class="menu app-sidebar animated" :class="{'show-sidebar':toggle}">
    <template v-for="(broker,broker_index) in user.brokers">
      <broker-navigation-component :key="broker_index" :broker="broker"></broker-navigation-component>
      <hr class="navbar-divider">
    </template>

    <template v-for="(insurer,insurer_index) in user.insurers">
      <insurer-navigation-component :key="insurer_index" :insurer="insurer"></insurer-navigation-component>
      <hr class="navbar-divider">
    </template>

  </aside>
</template>

<script>
import store from "../store";

export default {
  props: ["toggle"],
  data() {
    return {
      isReady: false
    };
  },
  watch: {
    toggle(val) {
      console.log(val);
    },
    user() {}
  },
  computed: {
    user() {
      if (store.state.user) {
        return store.state.user;
      }
      return {};
    }
  }
};
</script>

