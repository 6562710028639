<template>
  <div>
    <div :class="{'is-danger': errors!=null}" class="select is-small">
      <select v-model="selected" :disabled="readonly">
        <option disabled value=''  selected>Please select one</option>
        <option
          v-for="(option,index) in component.options"
          :value="option"
          :key="index"
        >{{translate_option(option)}}</option>
      </select>
    </div>

    <p class="help has-text-danger" v-if="errors">{{errors}}</p>

  </div>
</template>
<script>
import Vue from "vue";
import _ from "lodash";
import numeral from "numeral";
import store from "../../store";

export default {
  props: ["component", "enable", "errors", "answer", "mode"],
  data() {
    return {
      selected: "",
      optional: false
    };
  },
  mounted() {
    if (this.answer != null) {
      for (let a in this.component.options) {
        if (this.answer == this.component.options[a]) {
          this.selected = this.answer;
        }
      }
    } 
    else 
    //if (_.includes(this.component.options, "NA")) 
    {
          this.selected ='';
          this.$emit("input", '');
    }
  },
  computed: {
    readonly() {
      if (!this.enable) return true;
      if (this.mode && this.mode == "view") {
        return true;
      } else return false;
    }
  },
  watch: {
    selected(val) {
      this.$emit("input", val);
    },
    enable(val) {
      if (val == false) {
        this.optional = true;
        this.selected = "";
      } else this.optional = false;
    }
  },
  methods: {
    translate_option(value) {
      if (!isNaN(value)) return numeral(value).format("$0,0");

      switch (value) {
        case "A":
          return "Included";
          break;

        case "NA":
          return "Not Included";
          break;

        default:
          return value;
          break;
      }
    }
  }
};
</script>