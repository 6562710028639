import Vue from 'vue'
import moment from 'moment'
import numeral from "numeral"
Vue.filter('space_to_dash', function(value) {
    if (value) return value.replace(/ /g, "-");
    else return value;
});

Vue.filter('datetime', function(value) {
    return moment(value).format("DD-MM-YYYY, h:mm a");
});


Vue.filter('currency', function(value) {
    return numeral(value).format("$0,0.00");
});