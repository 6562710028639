<template>
  <nav class="level app-levelbar">
    <div class="level-left">
      <div class="level-item">
        <h3 class="subtitle is-5">
          <strong></strong>
        </h3>
      </div>
      <div class="level-item">

      </div>
    </div>

    <div class="level-right is-hidden-mobile">

    </div>
  </nav>
</template>

<script>


export default {

 
}
</script>
