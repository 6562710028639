<template>
  <div class="content" v-if="components">

    <b-table
      :data="components"
      :bordered="false"
      :striped="false"
      :narrowed="false"
      :hoverable="false"
      :loading="false"
      :focusable="false"
      :mobile-cards="true"
    >
      <template slot-scope="props">
        <b-table-column field="id" label="Id">{{ props.row.id }}</b-table-column>
         <b-table-column field="name" label="name">{{ props.row.name }}</b-table-column>
        <b-table-column field="code" label="code">{{ props.row.code }}</b-table-column>
      </template>

      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="emoticon-sad" size="is-large"></b-icon>
            </p>
            <p>Nothing here.</p>
          </div>
        </section>
      </template>
    </b-table>
  </div>
</template>



<script>
import store from "../../store";
import axios from "axios";
import mixin from "../../mixin";
export default {
  mixins:[mixin],
  props:['components'],
  data() {
    return {

    };
  },
  mounted()
  {
     //this.getInsurercomponents(this.$route.params.insurer_id);

  },
  methods: 
  {
  }
};
</script>