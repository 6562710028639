<template>
  <div  v-if="scheme">
    <div class="columns">
      <div class="column" v-if="scheme.settings">
        <p
          class="title p-md is-size-4"
          v-bind:style="{ background: scheme.settings.theme['background_color'] , color: scheme.settings.theme['text_color']  }"
        >{{scheme.name}}</p>
      </div>
      <div class="column">
        <span class="is-size-6">{{scheme.description}}</span>
      </div>
      <div class="column has-text-centered">Version: {{scheme.revision}}</div>
    </div>

    <b-tabs v-model="activeTab" expanded>
      <b-tab-item label="Fees">
        <vue-scrollbar classes="my-scrollbar" ref="Scrollbar">
          <div class="scroll-me">
            <div class="is-hidden-mobile columns  is-marginless p-b-xs has-text-weight-semibold  is-size-7" >
              <div class="column is-3 is-marginless is-paddingless">Status</div>

              <div class="column is-3 is-marginless is-paddingless">Title</div>
              <div class="column is-2 is-marginless is-paddingless">Type</div>
              <div class="column is-2 is-marginless is-paddingless">Amount</div>
              <div class="column is-2 is-marginless is-paddingless"></div>
            </div>
            <hr class="is-marginless">
            <hr class="is-marginless">
             <insurer-fee-component
              v-for="(fee,fee_index) in scheme.fees"
              :key="fee_index"
              :fee="fee"
              @update="UpdateFee($event,fee_index)"
            ></insurer-fee-component>
            <div class="clearfix"></div>
          </div>
        </vue-scrollbar>
      </b-tab-item>
      <b-tab-item label="Components">
        <vue-scrollbar classes="my-scrollbar" ref="Scrollbar">
          <div class="scroll-me">
            <insurer-scheme-components-component :components="scheme.components"></insurer-scheme-components-component>
            <div class="clearfix"></div>
          </div>
        </vue-scrollbar>
      </b-tab-item>
      <b-tab-item label="Occupations">
        <vue-scrollbar classes="my-scrollbar" ref="Scrollbar">
          <div class="scroll-me">
            <insurer-occupations-component :occupations="scheme.occupations"></insurer-occupations-component>
            <div class="clearfix"></div>
          </div>
        </vue-scrollbar>
      </b-tab-item>
    </b-tabs>
  </div>
</template>




<script>
import store from "../../store";
import axios from "axios";
export default {
  data() {
    return {
      pre_populate: false,
      scheme: {},
      isOpen: false,
      activeTab: 0,
      modal_height: 0
    };
  },
  watch: {
    scheme_id() {
      this.GetInsurerScheme();
    }
  },
  computed: {
    scheme_id() {
      return this.$route.params.id;
    }
  },
  mounted() {
    this.GetInsurerScheme();
  },
  methods: {
    UpdateFee(fee, index) {
     // console.log(fee);
      this.$set(this.scheme.fees, index, fee);
    },

    GetInsurerScheme() {
      let _self = this;
      let insurer_id = this.$route.params.insurer_id;

      axios
        .get(
          process.env.VUE_APP_API_URL +
            "/api/insurer/" +
            insurer_id +
            "/scheme/" +
            _self.scheme_id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            }
          }
        )
        .then(response => {
          _self.scheme = response.data;
        })
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.removeItem("token");
            store.commit("logoutUser");
            _self.$router.push({ name: "login" });
          }
        });
    },
    goto(name, params) {
      this.$router.push({ name: name, params: params });
    }
  }
};
</script>