<template>
  <div :class="{'field question':!readonly}">
    <div :class="{'columns':readonly}">
      <div :class="{'column is-three-quarters':readonly}">
        <div>
          <span v-if="question.title && readonly">{{question.title}}</span>
        </div>
      </div>

      <div :class="{'column ':readonly}">
        <div :class="{'is-pulled-right ':readonly}">
          <label class="label is-size-7" v-if="question.title  && !readonly">{{question.title}}<span class="has-text-danger" v-if="remark&&remark['self']">Refer</span></label>
          <div class="content" v-if="question.visible_descriprion">
            <span v-html="question.visible_descriprion"></span>
          </div>
           <p class=" has-text-weight-light is-7 is-family-code p-xs" style="font-size:0.5em" :class="[{ 'has-text-danger':(remark&&remark['self']) , 'has-text-gray':!(remark&&remark['self']) }]">{{question.id}}</p>
          <div class="control">
            
            <number-input-component
              v-if="question.type=='number'"
              :errors="errors"
              :answer="answer"
              :mode="mode"
              v-on:input="setInput"
            ></number-input-component>
            <currency-input-component
              v-if="question.type=='currency'"
              :errors=" errors "
              :answer="answer"
              :mode="mode"
              v-on:input="setInput"
            ></currency-input-component>
            <checkbox-input-component
              v-if="question.type=='checkbox'"
              :errors="errors"
              :label="question.label"
              :post_description="question.post_description"
              :answer="answer"
              :mode="mode"
              v-on:input="setInput"
            ></checkbox-input-component>
            <date-input-component
              v-if="question.type=='date'"
              :errors="errors"
              :answer="answer"
              :mode="mode"
              v-on:input="setInput"
            ></date-input-component>
            <text-input-component
              v-if="question.type=='text'"
              :errors="errors"
              :answer="answer"
              :mode="mode"
              v-on:input="setInput"
            ></text-input-component>
            <address-input-component
              v-if="question.type=='address'"
              :errors="errors"
              :answer="answer"
              :mode="mode"
              v-on:input="setInput"
            ></address-input-component>
            <radio-input-component
              v-if="question.type=='radio'"
              :errors="errors"
              :answer="answer"
              :mode="mode"
              :remark="remark"
              :actions="question.options"
              v-on:input="setInput"
            ></radio-input-component>
            <additive-input-component
              v-if="question.type=='additive'"
              :errors="errors"
              :answer="answer"
              :mode="mode"
              :remark="remark"
              :elements="question.elements"
              v-on:input="setInput"
            ></additive-input-component>
            <collection-input-component
              v-if="question.type=='collection'"
              :errors="errors"
              :answer="answer"
              :mode="mode"
              :remark="remark"
              :elements="question.elements"
              v-on:input="setInput"
            ></collection-input-component>
            <occupation-input-component
              v-if="question.type=='occopation_list'"
              :errors="errors"
              :answer="answer"
              :mode="mode"
              v-on:input="setInput"
            ></occupation-input-component>
            <state-input-component
              v-if="question.type=='zone_split'"
              :errors="errors"
              :answer="answer"
              :mode="mode"
              :zones="zones"
              v-on:input="setInput"
            ></state-input-component>
            <component-input-component
              v-if="question.type=='component'"
              :errors="errors"
              :answer="answer"
              :mode="mode"
              :enable="enableComponent"
              :component="getComponent(question.code)"
              v-on:input="setComponent($event,question.code)"
            ></component-input-component>

            <email-input-component
              v-if="question.type=='email'"
              :errors="errors"
              :answer="answer"
              :mode="mode"
              :enable="enableComponent"
              :component="getComponent(question.code)"
              v-on:input="setComponent($event,question.code)"
            ></email-input-component>

            <mobile-input-component
              v-if="question.type=='mobile'"
              :errors="errors"
              :answer="answer"
              :mode="mode"
              :enable="enableComponent"
              :component="getComponent(question.code)"
              v-on:input="setComponent($event,question.code)"
            ></mobile-input-component>


            <longtext-input-component
              v-if="question.type=='longtext'"
              :errors="errors"
              :answer="answer"
              :mode="mode"
              :enable="enableComponent"
              :component="getComponent(question.code)"
              v-on:input="setComponent($event,question.code)"
            ></longtext-input-component>


          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import axios from "axios";
import store from "../../store";
export default {
  props: ["question", "answer", "occupations", "errors", "mode","remark"],
  data() {
    return {
      enb: false,
    };
  },

  mounted() {


  },
  computed: {
    zones()
    {
        if(store.state.scheme && store.state.scheme.settings.zones)return store.state.scheme.settings.zones;
        else return {};

    },
    readonly() {
      return false;
      if (this.mode && this.mode == "view") {
        return true;
      } else return false;
    },
    enableComponent() {
      if (
        this.question.type != "component" ||
        typeof store.state.application.component[this.question.code] ==
          "undefined" ||
        typeof store.state.application.component[this.question.code][
          "enable"
        ] == "undefined"
      )
        return;
      else {
        this.$emit(
          "input",
          store.state.application.component[this.question.code]["enable"]
        );
        return store.state.application.component[this.question.code]["enable"];
      }
    }
  },
  watch: {
    errors(val) {
      console.log(val);
      //this.errors = val;
    },
    answer(val) {
      //  console.log("val");
      // console.log(val);
    },
    enableComponent(val)
    {

    }
  },
  methods: {

    setInput(value) {


      let question_id = this.question.id;
      let answer = {};
      answer[question_id] = value;
      //console.log(value);
      //console.log("question!"+question_id);
      //console.log(answer);
      this.$emit("input", answer);
    },
    setComponent(value, code) {
      //console.log(value);
      let component = {};
      for (let c in store.state.application.component) {
        component[c] = store.state.application.component[c];
      }
      if (typeof component[code] != "undefined") {
        component[code]["value"] = value;
      }
      store.commit("setComponentPropery", component);
      this.setInput(value);
    },
    getComponent(code) {

      for (let c in store.state.scheme.components) {
        if (store.state.scheme.components[c].code == code) {
         
        //  console.log(store.state.scheme.components);
          return store.state.scheme.components[c];
        }
      }
    }
  }
};
</script>

