<template >
  <ul class="menu-list">
    <!-- <p class="menu-label">
      <router-link
        :class="{'is-active is-success': ($route.name=='broker' && $route.params.broker_id==broker.id) }"
        :to="{ name: 'broker',params: { broker_id : broker.id, broker_name:slug(broker.name)}}"
      >{{slug(broker.name)}}</router-link>
    </p> -->
    <li>
      <router-link
        :class="{'is-active has-background-danger': ($route.name=='broker.policies' && $route.params.broker_id==broker.id) }"
        :to="{ name: 'broker.policies' ,params: { broker_id : broker.id, broker_name:slug(broker.name)}}"
      >Policies</router-link>
    </li>
    <li v-if="broker.id" v-bind="schemes">
      <a
        :class="{'is-active': ($route.name=='broker.scheme' && $route.params.broker_id==broker.id)  }"
        @click="showSchemes=!showSchemes"
      >Schemes</a>
      <ul lass="menu-list">
        <li v-for="(scheme , scheme_index) in schemes" :key="scheme_index">
          <router-link
            :class="{'is-active has-background-light': ($route.name=='broker.scheme' && $route.params.id==scheme.id ) }"
            :to="{ name: 'broker.scheme',params: {broker_id : broker.id, broker_name:slug(broker.name), scheme_code : scheme.code}}"
          >
            <span 
              class="tag"
              :style="{ background: scheme.settings.theme['background_color'] ,color: scheme.settings.theme['text_color']}"
            >{{scheme.code}}</span>
          </router-link>
        </li>
      </ul>
    </li>

  </ul>
</template>

<script>
import mixin from "../../mixin";
import axios from "axios";
import store from "../../store";
export default {
  props: ["broker"],
  mixins: [mixin],
  data() {
    return {
      schemes: {},
      showSchemes: true
    };
  },

  mounted() {
    this.GetSchemes();
  },
  methods: {
    GetSchemes() {
      let _self = this;
      let broker_id = this.broker.id;
      axios
        .get(
          process.env.VUE_APP_API_URL + "/api/broker/" + broker_id + "/schemes",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            }
          }
        )
        .then(response => {
          _self.schemes = response.data;
          console.log(_self.schemes);
        })
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.removeItem("token");
            store.commit("logoutUser");
            _self.$router.push({ name: "login" });
          }
        });
    }
  }
};
</script>