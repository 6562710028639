<template>
  <section>

      <b-datepicker :size="'is-small'"  placeholder="Type or select a date..." icon="calendar-today"  :disabled="readonly" v-model="
      value"   ></b-datepicker>

     <span v-show="errors" class="help has-text-danger">{{errors}}</span>
  </section>
</template>



<script>
import axios from "axios";
import moment from "moment"

export default {
  props: ["answer", "errors","mode"],
  data() {
    return {
      ready: false,
      value: null
    };
  },
  computed:
  {
    readonly()
    {
      if(this.mode && this.mode=='view')
      {
        return true;
      }
      else return false;
    }
  },
  watch: {
    value(val) {
      //console.log( moment(val).format());
      if (this.ready) this.$emit("input", moment(val).format());
    }
  },
  mounted() {
    console.log(this.mode);
    if (this.answer != null) this.value = new Date(this.answer);
    this.ready = true;
  }
};
</script> 