<template>
  <div class="overview">
    <div class="tile is-ancestor">
      
      <div class="tile is-vertical is-4 is-mobile" v-if="policy.status">
        <div class="tile">
          <div class="tile is-parent is-vertical">
            <article class="tile is-child notification">
              <p class="subtitle is-paddingless has-text-info ">Current Status</p>
              <template v-if="policy.status=='referred'">
                    <p class="has-text-info is-size-1 is-capitalized has-text-weight-bold" >Referred</p>
              </template>
              <template v-else-if="policy.status=='accepted'">
                  <p class="has-text-success is-size-1 is-capitalized has-text-weight-bold" >Accepted</p>
                  <b-button type="is-success is-small" @click="$emit('continue_application');">Continue</b-button>
              </template>
               <template v-else-if="policy.status=='quote'">
                  <p class="has-text-primary is-size-1 is-capitalized has-text-weight-bold" >Quote</p>
                   <b-button type="is-primary is-small" @click="$emit('bound_application');">Binding</b-button>
               </template>
                <template v-else-if="policy.status=='declined'">
                    <p class="has-text-danger is-size-1 is-capitalized has-text-weight-bold">Declined</p>
                </template>
                <template v-else>
                    <p class="is-size-1 is-capitalized has-text-weight-bold">{{policy.status}}</p>
                </template>
            </article>

          </div>
        </div>
      </div>


      <div class="tile is-vertical is-4 is-mobile" v-if="policy.policy_number">
        <div class="tile">
          <div class="tile is-parent is-vertical">
            <article class="tile is-child notification">
              <p class="subtitle">Policy Number:</p>
              <p class="title is-paddingless has-text-info">{{policy.policy_number}}</p>
              <span class="is-pulled-right"><small class="is-size-7">{{policy.bind_date}}</small> </span>
            </article>
          </div>
        </div>
      </div>

      <div class="tile is-vertical is-4 is-mobile">
        <div class="tile">
          <div class="tile is-parent is-vertical">
            <article class="tile is-child notification">
              <p class="subtitle">Quote Number:</p>
              <p class="title  has-text-primary">{{policy.quote_number}}</p>

              <span class="is-pulled-right"><small class="is-size-7">{{policy.quote_date}}</small></span>
            </article>
          </div>
        </div>
      </div>
    </div>
    <div class="tile is-ancestor"  >
      <div class="tile is-vertical is-4" v-if="policy.status=='bound'">
        <div class="tile is-parent">
          <article class="tile is-child notification">
            <div class="buttons m-sm is-clearfix">
              <b-button
                type="is-success"
                @click="GetPolicyDocument(policy.id,'schedule','pdf')"
              >Schedule
              </b-button>
              <b-button 
                type="is-success" 
                @click="GetPolicyDocument(policy.id,'certificate','pdf')"
              >Certificate of Currency</b-button>
            </div>
          </article>
        </div>
      </div>

      <div class="tile is-vertical is-4" v-if="policy.scheme">
        <div class="tile is-parent">
          <article class="tile is-child notification">
            <scheme-tile-component :scheme="policy.scheme" :size="'small'"></scheme-tile-component>
          </article>
        </div>
      </div>

      <div class="tile is-vertical is-4" v-if="policy.component && policy.scheme">
        <!-- Occupation -->
        <div class="tile" v-if="policy.occupation">
          <div class="tile is-parent">
            <article class="tile is-child notification">
              <p class="title is-size-6">Occupation</p>
              <div>
              <p class="sub-title is-size-7">{{policy.occupation}}</p>
              </div>
            </article>
          </div>
        </div>

        <!-- Components -->
        <div class="tile" v-if="policy.component.length>0">
          <div class="tile is-parent">
            <article class="tile is-child notification">
              <p class="title is-paddingless is-size-6">Components</p>

              <span
                class="columns is-paddingless is-marginless"
                v-for="(component,index) in policy.scheme.components"
                :key="index"
              >
                <template v-if="policy.component[component.code]!='NA'">
                  <span
                    class="column is-paddingless is-marginless is-two-thirds"
                  >{{component.name}} ({{component.code}}):</span>
                  <span
                    class="column is-paddingless is-marginless has-text-right"
                    v-html="icon(policy.component[component.code])"
                  ></span>
                </template>
              </span>
            </article>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import mixin from "../../mixin";
export default {
  props: ["policy"],
  mixins: [mixin],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    icon(code) {
      switch (code) {
        case "A":
          return '<i class="material-icons has-text-success ">check</i>';
          break;
        case "NA":
          return '<i class="material-icons has-text-danger ">close</i>';
          break;
        default:
          return '<span class=" has-text-success ">$' + code + "</span>";
          break;
      }
    }
  }
};
</script>