<template>
  <span
    class="scheme-badge"
    :style="[{background: scheme.settings.theme.background_color,color: scheme.settings.theme.text_color,'--font_size':font_size}]"
  >{{ scheme.code }}</span>
</template>




<script>
export default {
  props: ["scheme", "size"],
  data() {
    return {
      font_size: "1em"
    };
  },
  mounted() {
    switch (this.size) {
      case "small":
        this.font_size= "0.8em";
        break;
            case "large":
      this.font_size= "1.2em";
      break;
      default:
        this.font_size= "1em";
        break;
    }
  }
};
</script>