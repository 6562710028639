<template>

<div>
    <div v-if="broker" >
      <aside class="menu app-sidebar animated">
        <broker-navigation-component :broker="broker"></broker-navigation-component>
      </aside>
      <router-view :key="$route.fullPath" class="p-t-md" style="min-height: 75vh;"></router-view>
      <footer-bar :company="broker"></footer-bar>
    </div>
  </div>



</template>



<script>
import store from "../store";
import axios from "axios";
import mixin from "../mixin";
// import FeesComponent from "./Fees";

export default {

  mixins:[mixin],
  // components: {
  //   "fees-component": FeesComponent,
  // },
  data() {
    return {
      data: "nothing",
    };
  },
  computed: {
    broker() {
      for (let broker_index in store.state.user.brokers) {
        if (
          this.$route.params.broker_id ==
          store.state.user.brokers[broker_index].id
        ) {
          return store.state.user.brokers[broker_index];
        }
      }
      return null;
    },

  },
  mounted() {
 
  },
  methods: {

  }
};
</script>