<template>
    <article ref="quotetile_child" class="tile is-child notification quote-box" >
      <div>
        <template v-if="price">
          <p class="title is-size-5-tablet is-size-4-desktop is-size-1-mobile    has-text-centered">{{price | currency}}</p>
          <p class="subtitle is-size-7 has-text-centered">Including GST & Government Charges.</p>
          <slot></slot>
        </template>
        <p v-else class="title is-size-6 has-text-centered  has-text-danger" >Complete the form to generate the quote</p>

      </div>
    </article>
</template>




<script>

import axios from "axios";
import Errors from "../../classes/errors.js";
export default {
  props: ["errors","price"],
  data() {
    return {
    }
  }
}
</script>