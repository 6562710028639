<template>
  <section>
    <broker-quote-component
      v-if="edit_application"
      @updated="refresh"
      :c_policy="policy"
      :stage="edit_application_stage"
      :mode="'edit'"
      :broker_id="this.$route.params.broker_id"
    ></broker-quote-component>

    <b-tabs v-model="activeTab" expanded v-else>
      <b-tab-item label="Overview">
        <policy-overview-component
          @continue_application="edit_application=true; edit_application_stage='application';"
          @bound_application="edit_application=true; edit_application_stage='binding';"
          :policy="policy"
        ></policy-overview-component>
      </b-tab-item>

      <b-tab-item label="Quote" v-if="policy.quote && policy.quote['total']">
        <div class="block">
          <b-button type="is-primary is-small" @click="edit_quote=true;quote_preview= quote_view;"  v-if=" AccessLevel()=='insurer' && edit_quote==false">Edit</b-button>
        </div>
        
          <vue-scrollbar v-if="!edit_quote"
            classes="my-scrollbar"
            ref="Scrollbar"
            v-bind:style="{ height: modal_height*.7 + 'px' }"
          >
            <div class="scroll-me">
              <section v-html="quote_view"></section>
              <div class="clearfix"></div>
            </div>
          </vue-scrollbar>


                  <div class="columns is-desktop" v-if="edit_quote">
                    <div class="column is-4-desktop " v-if="policy.scheme &&  policy.scheme.components  &&  AccessLevel()=='insurer'">

                      <b-field
                        position="is-right"
                        custom-class="is-extra-small "
                        v-for="(component,component_index) in policy.scheme.components"
                        :key="component_index"
                      >
                      <span class="is-extra-small m-r-sm"> {{component.name}}</span>
                        <b-input
                          v-model="policy_quote[component.code]"
                          @input="updatePolicy({'request_type':'quote','request_mode':'preview', 'data':policy_quote })"
                          custom-class="is-extra-small"
                        ></b-input>
                        <small class="is-extra-small m-l-sm"  style="min-width:60px;">${{policy_quote[component.code]- policy.quote.component[component.code].base}}</small>
                      </b-field>
                      <div class="field is-grouped" >
                      <div class="control has-text-centered">
                        <b-button
                          type="is-small is-success" @click="updatePolicy({'request_type':'quote','request_mode':'store', 'data':policy_quote })">Save</b-button>
                      </div>

                      <div class="control has-text-centered ">
                        <b-button type="is-small is-danger"  @click="edit_quote=false; ">Cancel</b-button>
                      </div>
                    </div>
                    </div>
                    <div class="column">
                      <vue-scrollbar
                        classes="my-scrollbar"
                        ref="Scrollbar"
                        v-bind:style="{ height: modal_height*.7 + 'px' }"
                      >
                        <div class="scroll-me">
                          <section v-html="quote_preview"></section>
                          <div class="clearfix"></div>
                        </div>
                      </vue-scrollbar>
                    </div>
                  </div>
      </b-tab-item>

      <b-tab-item label="Application">
        <vue-scrollbar
          classes="my-scrollbar"
          ref="Scrollbar"
          v-bind:style="{ height: modal_height*.7 + 'px' }"
        >
          <div class="scroll-me">
            <section v-html="application_view"></section>
            <div class="clearfix"></div>
          </div>
        </vue-scrollbar>
      </b-tab-item>
      <b-tab-item label="Referrals" v-if="policy && policy.referrals && policy.referrals.length>0">
        <policy-referrals-component
          :access="AccessLevel()"
          :referrals="policy.referrals"
          @updated="updatePolicy($event)"
        ></policy-referrals-component>
      </b-tab-item>
    </b-tabs>
  </section>
</template>



<script>
import store from "../store";
import axios from "axios";
import mixin from "../mixin";
import Errors from "../classes/errors.js";
export default {
  props: ["id", "tab"],
  mixins: [mixin],
  components: {},
  data() {
    return {
      policy: {},
      refer: { edit: false, id: null, description: null },
      quote_view: null,
      quote_preview: null,
      application_view: null,
      policy_quote: {},
      edit_quote: false,
      isOpen: false,
      edit_application: false,
      edit_application_stage: null,
      errors: new Errors(),
      activeTab: 0,
      modal_height: window.innerHeight
    };
  },

  mounted() {
    this.getPolicy(this.id);
    this.GetPolicyView(this.id, "application");
    this.GetPolicyView(this.id, "quote");
  },
  methods: {
    AccessLevel() {
      if (this.$route.params.insurer_id) return "insurer";
      if (this.$route.params.broker_id) {
        return this.GetAccessLevel(this.$route.params.broker_id);
      }
      return "broker";
    },
    refresh(policy) {
      //console.log(policy);
      this.getPolicy(this.id);
      this.activeTab = 0;
      this.edit_application = false;
      this.edit_quote=false;
      this.activeTab = 0;
      this.GetPolicyView(this.id, "application");
      this.GetPolicyView(this.id, "quote");
    },
    updatePolicy(request) {
      let _self = this;

      console.log(this.$route.params.insurer_id);
      axios({
        method: "put",
        url:
          process.env.VUE_APP_API_URL +
          "/api/insurer/" +
          this.$route.params.insurer_id +
          "/policy/" +
          _self.policy.id,
        data: request,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      })
        .then(function(response) {
          if (request.request_mode == "store") {

                  _self.getPolicy(_self.policy.id);
                  _self.GetPolicyView(_self.policy.id, "application");
                _self.GetPolicyView(_self.policy.id, "quote");      _self.edit_quote=false;

          }
          if (request.request_type == "quote") {
           // console.log(response);
            _self.quote_preview = response.data;
            
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.removeItem("token");
            store.commit("logoutUser");
            _self.$router.push({
              name: "login"
            });
          }

          let response_errors = error.response.data.errors;
          console.log(response_errors);
          if (typeof response_errors == "undefined") return;
          _self.errors.record(response_errors);
        });
    },

    getPolicy(id) {
      let _self = this;
      let URL = "";
      if (this.$route.params.insurer_id) {
        URL =
          process.env.VUE_APP_API_URL +
          "/api/insurer/" +
          this.$route.params.insurer_id +
          "/policy/" +
          id;
      } else if (this.$route.params.broker_id)
        URL =
          process.env.VUE_APP_API_URL +
          "/api/broker/" +
          this.$route.params.broker_id +
          "/policy/" +
          id;

      axios
        .get(URL, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
          }
        })
        .then(response => {
          _self.policy = response.data;
          for (let component in response.data.quote.component) {
            _self.policy_quote[component] =
              response.data.quote.component[component].base;
          }
          // console.log(_self.policy_quote);
          //console.log(_self. AccessLevel(_self.policy.broker_id));
        })
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.removeItem("token");
            store.commit("logoutUser");
            this.$router.push({
              name: "login"
            });
          }
        });
    },

    GetPolicyView(id, view_name) {
      let _self = this;

      axios({
        method: "get",
        url:
          process.env.VUE_APP_API_URL +
          "/api/policy/" +
          id +
          "/view/" +
          view_name,
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      })
        .then(response => {
          if (view_name == "application")
            _self.application_view = response.data;
          if (view_name == "quote") { _self.quote_view = response.data;} 
        })
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.removeItem("token");
            store.commit("logoutUser");
            this.$router.push({
              name: "login"
            });
          }
        });
    }
  }
};
</script>


<style>
.is-extra-small {
  font-size: 0.6em;
}
.view-refer-star {
  color: red;
}
.view-questions {
  font-size: 0.9em;
}

.view-questions ol li {
  padding: 5px;
  margin-left: 35px;
}

.view-question-title {
  font-size: 0.9em;
}

.view-questions ol {
  line-height: 150%;
}
.check {
  content: "\2713";
}

.view-quote {
  width: 100%;
  font-size: 0.8em;
  table-layout: auto;
  border-collapse: collapse;
}

.view-quote td,
th,
tr {
  padding: 1px;
  border: solid black 1px;
}

.view-quote .view-table-break-row {
  height: 30px;
  border-right: none;
  border-left: none;
}
.view-zone-split {
  width: 100%;
  margin: 20px auto;
  table-layout: auto;
  border-collapse: collapse;
}

.view-zone-split td,
th,
tr {
  padding: 3px;
  border: solid black 1px;
}
</style>